import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
/* MUI */
import { Tooltip, Box } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

const BtnCopyToCourse = () => {
  const { t } = useTranslation('editCourseForm');
  const store = useStore();
  const { courseEdit } = store;
  const courseId = courseEdit.currentCourse.attributes.drupal_internal__nid;

  const copyToClipboard = async () => {
    const toCourseLabel = {
      pe: 'https://learn.pe-academy.nl/to-course',
      po: 'https://learn.po-online.nl/to-course',
      cme: 'https://learn.cme-online.nl/to-course',
      ewise: 'https://learn.e-wise.nl/to-course'
    };
    const toCourseUrl = `${toCourseLabel[store.label]}/${courseId}`;
    const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;

    const toast = {
      success: {
        title: t('editCourse.btnCopyToCourseLink.success.title'),
        content: `${toCourseUrl}`,
        type: 'success'
      },
      error: {
        title: t('editCourse.btnCopyToCourseLink.error.title'),
        content: t('editCourse.btnCopyToCourseLink.error.message'),
        type: 'error'
      },
      default: { timeOut: 4000, variant: 'outlined' }
    };
    // firefox doesn't support the permissions API
    if (isFirefox) {
      await navigator.clipboard.writeText(toCourseUrl);
      courseEdit.setToasts({ ...toast.success, ...toast.default });
    }
    if (!isFirefox) {
      try {
        const permissions = await navigator.permissions.query({ name: 'clipboard-write' });
        if (permissions.state === 'granted' || permissions.state === 'prompt') {
          await navigator.clipboard.writeText(toCourseUrl);
          courseEdit.setToasts({ ...toast.success, ...toast.default });
        } else {
          throw new Error("Can't access the clipboard. Check your browser permissions.");
        }
      } catch (error) {
        courseEdit.setToasts({ ...toast.error, ...toast.default });
      }
    }
  };
  return (
    <Tooltip title={t('editCourse.btnCopyToCourseLink.tooltip')} arrow>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }} onClick={() => copyToClipboard()}>
        <ContentPasteIcon size="large" color="primary" />
        {t('editCourse.btnCopyToCourseLink.btnTitle')}
      </Box>
    </Tooltip>
  );
};

export default observer(BtnCopyToCourse);
