import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Menu, Tooltip } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { useStore } from 'hooks';
import { useHeaderContext } from '../context';
import ProductMenuItems from './ProductMenuItems';
import * as Styled from './HeaderComponents.styles';

const SelectProductButton = () => {
  const { t } = useTranslation();
  const store = useStore();
  const isSearchRoute = store.routerStore?.getCurrentRoute().name === 'searchOverview';
  const isEwise = store.isEwiseUser ? 'EWISE ' : '';

  const {
    handleMenuClose,
    handlePopoverClose,
    handleMenuSelectProduct,
    isMenuProductsOpen,
    anchorProducts,
    handleMenuProductsOpen,
    showProductMenu,
    productName
  } = useHeaderContext();

  return (
    <>
      <Tooltip title={t('headerbar.selectProduct', 'Select product')} arrow>
        <Styled.Button aria-haspopup="true" aria-owns={anchorProducts ? 'simple-menu' : null} onClick={handleMenuProductsOpen}>
          {store?.products?.length > 1 && showProductMenu ? (
            <>
              <span>{productName?.base_product_title}</span>
              {anchorProducts ? <ExpandLess /> : <ExpandMore />}
            </>
          ) : (
            <>
              <span className="product_title">
                {isSearchRoute ? `${isEwise} ${store?.baseProduct?.title}` : store.subscriptions.subscriptions[0]?.base_product_title}
              </span>{' '}
            </>
          )}
        </Styled.Button>
      </Tooltip>

      {store?.products?.length > 1 && showProductMenu && (
        <Menu
          id="account-menu"
          anchorEl={anchorProducts}
          open={isMenuProductsOpen}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClose={handleMenuClose}
          onMouseOut={handlePopoverClose}
          BackdropProps={{ invisible: true }}
          disableScrollLock
          sx={{ '& .MuiMenu-paper': { borderRadius: '15px' } }}
        >
          <ProductMenuItems handleMenuSelect={handleMenuSelectProduct} />
        </Menu>
      )}
    </>
  );
};

export default observer(SelectProductButton);
