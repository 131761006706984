/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useState, useContext, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
/* MATERIAL TABLE */
import MaterialTable, { MTableToolbar } from '@material-table/core';
/* MUI */
import { Box, Chip } from '@mui/material';
/* ICONS */
import { AutoFixHigh, ContentCopy } from '@mui/icons-material';
/* HELPERS */
import { dateDiff, convertDate } from '../../helpers/utils';
/* CUSTOM COMP */
import LoaderWithBackdrop from '../loading/LoaderWithBackdrop';
import AdvancedCourseScreen from '../advancedCourseActions/AdvancedCourseScreen';
import CopyCourseDialog from '../advancedCourseDialog/CopyCourseDialog';
import TableFilterItems from './TableFilterItems';

/*
In summary, the EwapiTable component renders a table of courses with various customization options and actions.
 Users can interact with the table to edit courses, trigger advanced course actions, and copy course data. 
The component provides a user-friendly interface for managing courses within the application.
*/
const EwapiTable = ({ loading, title, courses, summary }) => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');
  const theme = useTheme();
  const { courseEdit } = store;
  //
  const isMicrolearning = courseEdit?.packageMenuList?.find((item) => item.package_id === +store.params.packageNid).microlearning;

  const tableRef = useRef(null);

  // STATE
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [openAdvancedScreen, setOpenAdvancedScreen] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  //
  const defaultOptions = {
    actionsColumnIndex: -1,
    columnsButton: true,
    debounceInterval: 500,
    emptyRowsWhenPaging: false,
    filtering: false,
    grouping: false,
    padding: 'dense',
    pageSize: 25,
    pageSizeOptions: [5, 10, 25, 50, 100, 200],
    paginationType: 'stepped',
    numberOfPagesAround: 1,
    searchFieldStyle: { border: `2px solid ${theme.palette.primary.light}` },
    searchFieldVariant: 'outlined',
    selection: false
  };

  // TABLE OPTIONS: some options are updatable by switch buttons
  const [options, setOptions] = useState(defaultOptions);

  // ON CLICK EXTERNAL ACTIONS ICON: TOGGLE OPEN/CLOSE DIALOG FOR EXTERNAL COURSE ACTIONS
  const togggleAdvancedScreen = useCallback(() => setOpenAdvancedScreen(!openAdvancedScreen), [openAdvancedScreen]);

  // ON CLICK ROW ACTION ICON: TOGGLE DIALOG FOR INTERNAL COURSE ACTIONS
  const togggleCopyDialog = useCallback(
    (value) => {
      setOpenCopyDialog(!openCopyDialog);
      store.courseEdit.setStoreValue('setCopyCourse', value || {});
    },
    [openCopyDialog, store.courseEdit]
  );

  // ON CLICK ROW: goto edit course
  const handleEditCourse = (course) => {
    const { course_uuid: courseUuid, course_container: linked } = course;
    const isLinked = linked.length > 0;
    setShowBackDrop(true);
    const { package: _package, containerNid, packageNid } = store.routerStore.routerState.params;
    let routerParams = {
      params: { package: _package.toString(), packageNid, containerNid, courseuuid: courseUuid }
    };
    if (isLinked) routerParams = { ...routerParams, queryParams: { type: 'linked' } };
    store.routerStore.goTo('editCourse', routerParams);
  };

  // ON CLICK SWITCHES: update table options
  const clickHandler = (optionKey) => {
    const currentValue = options[optionKey];

    if (optionKey === 'padding') {
      const newValue = currentValue === 'dense' ? 'normal' : 'dense';
      setOptions((prev) => ({ ...prev, [optionKey]: newValue }));
      return;
    }

    // when we disable filtering, clear values in all filter cells...
    if (tableRef.current && optionKey === 'filtering' && currentValue) {
      tableRef.current.dataManager.columns.forEach((item) => {
        tableRef.current.onFilterChange(item.tableData.id, '');
      });
    }

    setOptions({ ...options, [optionKey]: !currentValue });
  };

  const linked = (courseContainers) => {
    if (courseContainers.length === 0) return '';
    const containers = courseContainers.map((container) => (
      <Chip
        sx={{ fontSize: '10px', height: 'auto', padding: `1px 2px` }}
        color="secondary"
        key={container.course_container_nid}
        label={container.course_container_title.slice(0, 15)}
        size="small"
      />
    ));
    return containers;
  };

  // TABLE DATA ____________________________________________________________
  const tableData = useMemo(
    () =>
      courses.map((course) => ({
        id: course.course_nid,
        course_nid: course.course_nid,
        course_uuid: course.course_uuid,
        course_title: course.course_title,
        course_status: course.course_status,
        course_categories: course.course_categories.map((category) => category.name).join(', ') || '',
        course_categories2: course.course_categories2.map((category) => category.name.charAt(0)).join(', ') || '',
        course_online: course.course_online,
        course_validFrom: course.valid_period,
        course_validEnd: course.valid_period_end,
        course_points: course.course_points,
        course_container: course.course_container,
        course_reUsed: course.course_profession !== course.lesson_container_profession,
        course_course_status: course.course_course_status
      })),
    [courses]
  );

  // TABEL FILTERS __________________________________________________________
  const filterStatus = useCallback(() => {
    const filter = {};
    courses.forEach((course) => {
      filter[course.course_course_status] = course.course_course_status;
    });
    return filter;
  }, [courses]);

  // TABLE COLUMNS __________________________________________________________
  const columns = useMemo(
    () => [
      { title: 'id', field: 'id', cellStyle: { width: '50px' }, grouping: false },
      {
        title: t('dashboard.course'),
        field: 'course_title',
        cellStyle: { width: '300px', overflow: 'hidden', textOverflow: 'ellipsis' },
        grouping: false
      },
      { title: 'pnt', field: 'course_points' },
      { title: t('dashboard.categories'), field: 'course_categories' },
      { title: t('dashboard.competences'), field: 'course_categories2' },
      {
        title: t('dashboard.start'),
        field: 'course_validFrom',
        cellStyle: { whiteSpace: 'nowrap' },
        render: (rowData) => convertDate(rowData.course_validFrom),
        hidden: true,
        grouping: false,
        filtering: false
      },
      {
        title: t('dashboard.end'),
        field: 'course_validEnd',
        cellStyle: { whiteSpace: 'nowrap' },
        render: (rowData) => convertDate(rowData.course_validEnd),
        grouping: false,
        filtering: false
      },
      {
        title: t('dashboard.daysLeft'),
        field: 'course_validEnd',
        render: (rowData) => dateDiff(rowData.course_validEnd),
        grouping: false,
        filtering: false
      },
      {
        title: t('dashboard.linked'),
        field: 'course_container',
        render: (rowData) => linked(rowData.course_container),
        grouping: false,
        filtering: false
      },
      {
        title: 'draft',
        field: 'course_status',
        grouping: true,
        filtering: true,
        hidden: true
      },
      {
        title: t('dashboard.status'),
        field: 'course_course_status',
        grouping: true,
        filtering: true,
        lookup: filterStatus()
      }
    ],
    [filterStatus, t]
  );
  // TABLE ACTIONS __________________________________________________________
  // microlearnings don't have the extraCourseActions button
  const actions = useMemo(() => {
    const extraCourseActions = isMicrolearning
      ? []
      : [
          {
            icon: () => <AutoFixHigh size="large" color="primary" sx={{ fontSize: '30px' }} />,
            tooltip: t('dashboard.extraCourseActions'),
            isFreeAction: true,
            onClick: togggleAdvancedScreen
          }
        ];
    return [
      {
        icon: () => <ContentCopy size="small" sx={{ color: 'silver', margin: '0px 5px' }} />,
        tooltip: t('dashboard.extraCourseActions'),
        onClick: (event, rowData) => togggleCopyDialog(rowData)
      },
      ...extraCourseActions
    ];
  }, [isMicrolearning, t, togggleAdvancedScreen, togggleCopyDialog]);

  const courseCount = courses.length;

  return (
    <Box sx={sxAdminEwpaiTables} className="admin__ewapi_tables">
      <Box className="ewapi-table-container">
        <LoaderWithBackdrop open={showBackDrop} />
        <MaterialTable
          tableRef={tableRef}
          onRowClick={(event, rowData) => handleEditCourse(rowData)}
          isLoading={loading}
          title={`${title} ${t('dashboard.courses')}: ${courseCount} `}
          columns={columns}
          data={tableData}
          options={options}
          localization={{ toolbar: { searchPlaceholder: t('dashboard.search') } }}
          actions={actions}
          components={{
            Toolbar: (props) => (
              <Box className="mtoolbar__container">
                <MTableToolbar {...props} />
                <Box className="mtoolbar_filters_button" sx={sxFilters}>
                  <TableFilterItems options={options} clickHandler={clickHandler} />
                </Box>
              </Box>
            )
          }}
        />
      </Box>
      <AdvancedCourseScreen summary={summary} open={openAdvancedScreen} toggleAdvancedScreen={togggleAdvancedScreen} />
      <CopyCourseDialog open={openCopyDialog} toggleCopyDialog={togggleCopyDialog} />
    </Box>
  );
};

EwapiTable.propTypes = {
  loading: PropTypes.any,
  title: PropTypes.string,
  courses: PropTypes.array,
  summary: PropTypes.object
};

export default observer(EwapiTable);

// CUSTOM SX ___________________________________________________________
const sxFilters = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px 0px',
  marginBottom: '-10px',
  label: { flexDirection: 'row' }
};

const sxAdminEwpaiTables = {
  flexGrow: 1,
  display: 'flex',
  '& .ewapi-table-container': { width: '100%', [`table thead tr th:last-of-type`]: { paddingRight: '15px' } },
  h6: { textTransform: 'capitalize' },
  table: { background: 'white' },
  'table tbody': { background: '#bdbdbd17' }
};
