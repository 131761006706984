import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
/* MUI */
import { Typography, Card, CardContent, Grid, SvgIcon, CardActions } from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Quiz from 'components/icons/Quiz';

import CertificateTab from 'components/icons/CertificateTab';
import { getLabelNameFromDomain, GetTimeLabel } from 'helpers/Helpers';
import CourseButton from '../CourseButton';
import TrialCourseButton from '../TrialCourseButton';

const MobileTopbarCard = (props) => {
  const store = useStore();
  const { lessons, accreditation, duration, extraPoints, extraPointsDescr, isAccredited } = props;
  const { t } = useTranslation();
  const currentTheme = getLabelNameFromDomain();
  const certTitle =
    currentTheme && currentTheme === 'po' ? t('introduction.sidebar.certificationPo') : t('introduction.sidebar.certification');
  const timeLabel = GetTimeLabel(duration);

  const isNonTrialCourse =
    (store.isTrialProduct && store.trialCourses && !store.trialCourseIds.includes(Number(store.params.courseId))) || false;

  return (
    <Card>
      <CardContent className="course_details">
        <Grid container>
          {!extraPoints && duration !== '' && parseFloat(duration) !== 0 && (
            <Grid item xs={12}>
              <Grid container className="spec" alignItems="center">
                <Grid item className="icon">
                  <AccessAlarmIcon color="#000" />
                </Grid>
                <Grid item>
                  <Typography variant="h1" color="#000" fontSize="16px" lineHeight="15px">
                    {t('introduction.sidebar.duration')}
                  </Typography>
                  <Typography color="#000" fontSize="12px" lineHeight="15px">
                    {timeLabel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isAccredited && (
            <Grid item xs={12}>
              <Grid container className="spec" alignItems="center">
                <Grid item className="icon">
                  <SvgIcon component={CertificateTab} color="#000" />
                </Grid>
                <Grid item>
                  <Typography variant="h1" color="#000" fontSize="16px" lineHeight="15px">
                    {certTitle}
                  </Typography>
                  <Typography color="#000" fontSize="12px" lineHeight="15px">
                    {extraPointsDescr || accreditation}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {lessons && lessons.length > 0 && (
            <Grid item xs={12}>
              <Grid container className="spec" alignItems="center">
                <Grid item className="icon">
                  <SvgIcon component={Quiz} color="#000" />
                </Grid>
                <Grid item>
                  <Typography variant="h1" color="#000" fontSize="16px" lineHeight="15px">
                    {t('introduction.sidebar.quiz')}
                  </Typography>
                  <Typography variant="h1" color="#000" fontSize="12px" lineHeight="15px">
                    {lessons[0].minimum_score}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions>{isNonTrialCourse ? <TrialCourseButton /> : <CourseButton />}</CardActions>
    </Card>
  );
};

MobileTopbarCard.propTypes = {
  lessons: PropTypes.any,
  duration: PropTypes.any,
  extraPoints: PropTypes.any,
  extraPointsDescr: PropTypes.any,
  isAccredited: PropTypes.bool,
  accreditation: PropTypes.any
};

export default observer(MobileTopbarCard);
