import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
// MUI
import { Button, Grid, Divider, Box } from '@mui/material';
// CUSTOM COMPONENTS
import ImageUploader from 'pages/adminCourses/components/RHF/ImageUploader';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useEffect, useState } from 'react';

/*
  Overall, this component is responsible for allowing the user to edit or upload images associated with a course,
  specifically for the course header, course photo, and external image. 
  The ImageUploader component is used to manage the image selection and uploading process.
*/
const ImageEditForm = ({ control, isDisabled, ...restProps }) => {
  const { t } = useTranslation('editCourseForm');
  const { values: initialValues, resetField, setValue, setError, clearErrors } = restProps;
  const [isImgExtRequired, setIsImgExtRequired] = useState(false);
  const [isImgIntRequired, setIsImgIntRequired] = useState(false);

  const watchImgInt = useWatch({ control, name: 'lessonContainer.relationships.field_coursephoto' });
  const watchImgExt = useWatch({ control, name: 'lessonContainer.relationships.field_course_image_extern' });
  const watchStatus = useWatch({ control, name: 'course.attributes.status' });
  const watchOnline = useWatch({ control, name: 'course.attributes.online' }) === '1';
  const isPublished = watchStatus && watchOnline;

  // image extern
  useEffect(() => {
    const setRequired = { types: { required: 'This is required' } };
    if (isPublished && !watchImgExt?.filename && !watchImgExt) {
      setError('lessonContainer.relationships.field_course_image_extern', setRequired);
      setIsImgExtRequired(true);
    } else {
      clearErrors('lessonContainer.relationships.field_course_image_extern');
      setIsImgExtRequired(false);
    }
  }, [clearErrors, isPublished, setError, watchImgExt]);

  // image intern = coursephoto
  useEffect(() => {
    const setRequired = { types: { required: 'This is required' } };
    if (isPublished && !watchImgInt?.filename && !watchImgInt) {
      setError('lessonContainer.relationships.field_coursephoto', setRequired);
      setIsImgIntRequired(true);
    } else {
      clearErrors('lessonContainer.relationships.field_coursephoto');
      setIsImgIntRequired(false);
    }
  }, [clearErrors, isPublished, setError, watchImgInt]);

  const imgSize = {
    headerPhoto: { maxWidth: 1920, maxHeight: 640 },
    coursePhoto: { maxWidth: 480, maxHeight: 270 },
    imageExtern: { maxWidth: 720, maxHeight: 540 }
  };

  return (
    <Box className="box-left" sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} sx={sxTabImages}>
        <Grid item xs={12} className="mediaButtonGrid">
          <Button
            sx={{ fontSize: '10px' }}
            size="small"
            variant="contained"
            color="secondary"
            target="_blank"
            href="https://media.e-wise.com"
            endIcon={<OpenInNewIcon />}
          >
            Open medialibrary
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider>
            {t('imageEditForm.courseHeader')}: {`${imgSize.headerPhoto.maxWidth} x ${imgSize.headerPhoto.maxHeight}`}
          </Divider>

          <Box className="img-box large" sx={{ flexGrow: 1 }}>
            <Box className="courseheaderPhoto" sx={{ position: 'relative' }}>
              <ImageUploader
                disabled={isDisabled('lessonContainer')}
                name="lessonContainer.relationships.field_course_header_photo"
                control={control}
                resetField={resetField}
                setValue={setValue}
                editMode
                src={initialValues.lessonContainer.relationships.field_course_header_photo}
                buttonName={t('imageEditForm.courseHeader')}
                index={0}
                {...imgSize.headerPhoto}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ color: isImgIntRequired ? 'red!important' : 'inherit' }}>
            {t('imageEditForm.coursePhoto')} : {`${imgSize.coursePhoto.maxWidth} x ${imgSize.coursePhoto.maxHeight}`}
          </Divider>
          <Box>
            <ImageUploader
              disabled={isDisabled('lessonContainer')}
              name="lessonContainer.relationships.field_coursephoto"
              control={control}
              resetField={resetField}
              setValue={setValue}
              editMode
              src={initialValues.lessonContainer.relationships.field_coursephoto}
              buttonName={t('imageEditForm.coursePhoto')}
              index={1}
              {...imgSize.coursePhoto}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ color: isImgExtRequired ? 'red!important' : 'inherit' }}>
            {t('imageEditForm.imageExtern')}: {`${imgSize.imageExtern.maxWidth} x ${imgSize.imageExtern.maxHeight}`}
          </Divider>
          <Box>
            <ImageUploader
              disabled={isDisabled('lessonContainer')}
              name="lessonContainer.relationships.field_course_image_extern"
              control={control}
              resetField={resetField}
              setValue={setValue}
              editMode
              src={initialValues.lessonContainer.relationships.field_course_image_extern}
              buttonName={t('imageEditForm.imageExtern')}
              index={2}
              {...imgSize.imageExtern}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
ImageEditForm.propTypes = {
  control: PropTypes.any,
  resetField: PropTypes.any,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  isDisabled: PropTypes.func
};
export default observer(ImageEditForm);

const sxTabImages = {
  '& .mediaButtonGrid': { display: 'flex', justifyContent: 'flex-end', padding: '0', margin: '0' },
  '& .headerPhoto img': { width: '100%', height: 'auto', maxHeight: '300px', maxWidth: '1920px' },
  '& .coursePhoto img': { width: '100%', height: 'auto', maxHeight: '270px', maxWidth: '480px' },
  '& .imageExtern img': { width: '100%', height: 'auto', maxHeight: '540px', maxWidth: '360px' },
  '& .headerPhoto div': { maxHeight: '300px', maxWidth: '100%' },
  '& .coursePhoto div': { maxHeight: '270px', maxWidth: '100%' },
  '& .imageExtern div': { maxHeight: '540px', maxWidth: '100%' }
};
