/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
// MUI
import { Grid, DialogContent, DialogActions, Box, Button, Backdrop, CircularProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
/* ICONS */
import UndoIcon from '@mui/icons-material/Undo';
import ArchiveIcon from '@mui/icons-material/Archive';
import ClearIcon from '@mui/icons-material/Clear';
/* Custom RHF components */
import { Form } from 'components/form';
import { MyTextField } from 'components/form/RHF';
import MyAutocompleteTaxonomy from '../../RHF/MyAutocompleteTaxonomy';
import { newCourseValidation } from './beans/NewCourse.ValidationSchema';

const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};

const defaultValues = {
  attributes: {
    title: '',
    field_points: '',
    field_extra_points: '',
    field_valid_period: { value: null, end_value: null }
  },
  field_course_category: []
};

const NewCourseForm = ({ handleNewCourseModal }) => {
  const store = useStore();
  const { t } = useTranslation('editCourseForm');
  const [saving, setSaving] = useState(false);

  const toast = {
    success: { title: t('addCourse.success.title'), content: t('addCourse.success.message'), type: 'success' },
    fail: { title: t('addCourse.fail.title'), content: t('addCourse.fail.message'), type: 'error' },
    default: { timeOut: 5000, variant: 'filled' }
  };

  /* ON SUBMIT / HANDLE SUBMIT */
  const handleSubmit = async (values) => {
    setSaving(true);
    const courseCategory = values.field_course_category.length > 0 ? values.field_course_category.map(({ title, ...rest }) => rest) : null;
    //
    const newCourse = {
      type: 'course',
      attributes: {
        ...values.attributes,
        field_valid_period: {
          value: values.attributes.field_valid_period.value?.toISOString()?.split('T')[0],
          end_value: values.attributes.field_valid_period.end_value?.toISOString()?.split('T')[0]
        },
        status: false,
        course_status: 'New',
        expected: false,
        online: false,
        field_skip_evaluation: store?.courseEdit?.currentPackage?.microlearning
      },
      relationships: {
        course_category: {
          data: courseCategory
        }
      }
    };
    try {
      await store.courseEdit.postCourse(newCourse);
      //
      const { package: _package, containerNid, packageNid } = store.params;
      const { newCourseId } = store.courseEdit;
      const routerParams = { params: { package: _package.toString(), packageNid, containerNid, courseuuid: newCourseId } };
      // show succes toast
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
      // close new course modal
      handleNewCourseModal();
      // redirect to edit course
      store.routerStore.goTo('editCourse', routerParams);
      setSaving(false);
    } catch (error) {
      handleNewCourseModal();
      setSaving(false);
      store.courseEdit.setToasts({ ...toast.fail, ...toast.default });
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={defaultValues} validationSchema={newCourseValidation}>
      {({ control, errors, dirty, reset }) => (
        <>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={saving}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent>
            <Grid container spacing={1} sx={sxGrid}>
              <Grid item xs={12}>
                <MyTextField
                  autoFocus
                  margin="normal"
                  name="attributes.title"
                  label={t('addCourse.form.title')}
                  type="text"
                  fullWidth
                  control={control}
                  error={errors?.attributes?.title}
                  helperText={errors?.attributes?.title && errors.attributes.title?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <MyAutocompleteTaxonomy
                  control={control}
                  name="field_course_category"
                  label={t('addCourse.form.categories')}
                  error={errors?.field_course_category}
                  helperText={errors?.field_course_category && errors?.field_course_category?.message}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', padding: '25px' }}>
            <Box>
              <Button onClick={() => reset()} variant="text" startIcon={<UndoIcon />} disabled={!dirty}>
                {t('addCourse.form.reset')}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', columnGap: '20px' }}>
              <Button
                onClick={() => {
                  reset();
                  handleNewCourseModal();
                }}
                disableElevation
                variant="contained"
                endIcon={<ClearIcon />}
                sx={{ background: '#607d8b' }}
              >
                {t('addCourse.form.close')}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                endIcon={<ArchiveIcon />}
                loading={saving}
                loadingPosition="end"
                disabled={!dirty}
              >
                {t('addCourse.form.save')}
              </LoadingButton>
            </Box>
          </DialogActions>
        </>
      )}
    </Form>
  );
};

NewCourseForm.propTypes = {
  handleNewCourseModal: PropTypes.func
};

export default observer(NewCourseForm);
