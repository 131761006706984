/* eslint-disable camelcase */
import { types as t, applySnapshot, getSnapshot, flow, getEnv } from 'mobx-state-tree';
import * as logger from 'loglevel';
import { buildLessonPages } from 'pages/play/PageParsing';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import Cmi5 from '@xapi/cmi5';
import { createCmi5JourneyHooks } from 'helpers/cmi5';
import { isCourseFailed } from 'helpers/Helpers';
import * as Sentry from '@sentry/react';
import { shuffledQuestionWidgetsArray } from 'pages/play//lib/shuffledQuestionWidgetsArray';
import { LessonContent } from './LessonContent';
import { Lesson } from './Lesson';
import { LessonResult } from './LessonResult';
import { CourseResult } from './CourseResult';
import { QuestionWidget } from './QuestionWidget';
import { PollWidgetList, pollWidgetLessonPlayActions, pollWidgetLessonPlayViews } from './PollWidget';
import { wordCloudLessonPlayActions, WordCloudList, wordCloudLessonPlayViews } from './WordCloudWidget';

const log = logger.getLogger('LessonPlay');
log.setDefaultLevel('info');

export const LessonPlay = t
  .model('LessonPlay', {
    lesson: Lesson,
    lesson_content: t.maybeNull(LessonContent),
    question_widgets: t.maybeNull(t.array(QuestionWidget)),
    lesson_result: t.maybeNull(LessonResult),
    pollWidgetList: t.maybeNull(PollWidgetList),
    wordCloudList: t.maybeNull(WordCloudList),
    allQuestionWidgets: t.maybeNull(t.array(QuestionWidget))
  })
  .volatile(() => ({
    store: null,
    jsonApi: null,
    cmi5Client: null,
    lmsApi: null,
    movement: { direction: '', left: '', right: '', center: '' }, // Horizontal page scroll
    location: undefined, // For scroll to vertical page location (#id)
    prevPage: null,
    pageNo: null,
    selectedToc: null,
    pageList: [], // pageList of cached pages.
    isNextPageEnabled: false, // Cannot be derrived (view-method or computed) because pageList in volatile is not deeply observable
    isTocScroll: false,
    reload: 0,
    isOpenWm: false,
    widgetCode: '',
    widgetMenuItem: { action: 'new', widgetId: '' },
    courseResult: t.maybeNull(CourseResult),
    reflectionList: [],
    isSaving: '',
    showDependingPopup: false,
    dependingCourse: null
  }))
  .views((self) => ({
    get jsonWidgets() {
      return self.question_widgets;
    },
    get pages() {
      return self.lesson_content.attributes.pages;
    },
    questionsAnswered(pageNo) {
      pageNo = pageNo || self.pageNo;
      pageNo = self.pageNo === 0 ? 1 : pageNo;

      return self.pageList[pageNo - 1].questionsAnswered === self.pageList[pageNo - 1].countQuestions;
    },
    get allQuestionsAnswered() {
      for (let i = 1; i <= self.totalPages; i++) {
        if (!self.questionsAnswered(i)) return false;
      }
      return true;
    },
    get highestSelectableToc() {
      // Only used for Cypress testing
      if (self.freeFlow === 'Off') {
        const lastEnabled = self.canPageBeCompleted(self.page_id_max) ? self.page_id_max + 1 : self.page_id_max;
        return self.reverseToc.find((toc) => toc.pagenr <= lastEnabled).id;
      }
      return self.reverseToc[0].id; // Allways the last toc-line
    },

    wasNeighbourPage() {
      return self.prevPage && (self.prevPage - 1 === self.pageNo || self.prevPage + 1 === self.pageNo);
    },

    isPageEnabled(pageNo = self.pageNo) {
      return (
        self.freeFlow !== 'Off' || pageNo <= self.page_id_max || (pageNo === self.page_id_max + 1 && self.questionsAnswered(pageNo - 1))
      );
    },

    canPageBeCompleted(pageNo) {
      if (self.freeFlow !== 'Full') {
        return pageNo < self.page_id_max || (pageNo === self.page_id_max && self.questionsAnswered(pageNo));
      }
      return self.questionsAnswered(pageNo);
    },

    isPageCompleted(pageNo) {
      return self.lesson_result.attributes.pages[pageNo - 1]?.completed;
    },

    getValidPage(pageNo) {
      pageNo = Math.abs(pageNo);
      if (pageNo <= 1) return pageNo;
      if (self.freeFlow === 'Full') return Number.isNaN(pageNo) || pageNo > self.totalPages ? 1 : pageNo;
      if (
        Number.isNaN(parseInt(pageNo, 10)) ||
        (self.freeFlow === 'Off' && (pageNo > self.page_id_max + 1 || !self.questionsAnswered(pageNo - 1)))
      ) {
        return self.page_id_max || 1;
      }
      if (pageNo > self.totalPages) return self.totalPages;
      return pageNo;
    },

    get canFinishLesson() {
      if (self.freeFlow === 'Full') return self.allQuestionsAnswered;
      return self.page_id_max === self.totalPages && this.canPageBeCompleted(self.page_id_max);
    },
    get freeFlow() {
      return self.lesson.attributes.free_flow;
    },
    showResumeBar(pageNo = self.pageNo) {
      return self.freeFlow === 'Normal' && !(self.page_id_max === pageNo || (self.page_id_max + 1 === pageNo && self.waitIncreasePageNo));
    },
    get page_id_max() {
      return self.lesson_result.attributes.page_id_max;
    },
    get widgets() {
      return self.lesson_content.attributes.widgets;
    },
    get widgetsOnPageMax() {
      return self.lesson_content.attributes.widgets[self.page_id_max - 1];
    },
    // get mappedPageWidgets() {
    //   if (self.widgetOnPage().length < 1) return false;
    //   const pageWidgets = self.widgetsOnPageMax().map((w) => {
    //     const pageWidgetQuestionProperties = _getQuestionWidgetProperties(w);
    //     const pageWidgetAnsweredProperties = _getLessonResultQuestionProperties(pageWidgetQuestionProperties);
    //     return { ...pageWidgetQuestionProperties, ...pageWidgetAnsweredProperties };
    //   });
    // },
    get totalPages() {
      return self.lesson_content.attributes.pages.length;
    },
    get title() {
      return self.lesson.attributes.title;
    },
    get reverseToc() {
      return [...self.lesson_content.attributes.toc].reverse();
    },
    get isLti() {
      return self.lesson_result.attributes.is_lti > 0;
    },
    allowedTries(widget) {
      // Show greyout without feedback
      const showFeedback1 = self.store?.lessonPlay?.lesson?.attributes['1answer_nofeedback'] || false;
      // Show color without feedback
      const showFeedback2 = self.store?.lessonPlay?.lesson?.attributes['1answer_visualfeedback'] || false;
      // Define max attempts for this widget
      const maxAttempts = !showFeedback1 && !showFeedback2 ? 2 : 1;
      return widget.attributes.widget_type === 'Radio Question' && widget.attributes.options.length === 2 ? 1 : maxAttempts;
    },
    isChildPage(parentPageNo, childPageNo) {
      if (childPageNo <= parentPageNo) return false;
      const foundParent = self.nestedToc.find((page) => {
        if (page.list.length) {
          return page.list.find((childPage) => childPage.details.pagenr >= childPageNo);
        }
        return false;
      });
      return !!(foundParent && foundParent.details.pagenr === parentPageNo);
    },
    get nestedToc() {
      // timer("build nested TOC");
      const newToc = self.lesson_content.attributes.toc.reduce((tocList, chapter) => {
        if (chapter.level === 0) {
          tocList.push({ details: chapter, list: [] });
        } else {
          tocList[tocList.length - 1].list.push({ details: chapter });
        }
        return tocList;
      }, []);
      // log.debug(timer("build nested TOC"));
      return newToc;
    },
    getLessonResultQuestion(widget) {
      const lr = self.lesson_result;

      let question;
      if (lr.attributes.questions) {
        question = lr.attributes.questions.find((q) => q.questionId === widget.id);
      } else lr.attributes.questions = [];
      if (!question) {
        question = {};
        question.questionId = widget.id;
        question.type = widget.widgetType;
        question.weight = widget.attributes.weight;
        question.last_correct = false;
        question.tries = 0;
      }
      return question;
    }
  }))
  // # views --> useWidget.jsx
  .views((self) => ({
    get answeredQuestions() {
      return JSON.parse(JSON.stringify(self.lesson_result.attributes.questions));
      // return self.lesson_result.attributes.questions;
    },
    get widgetsOnPageMaxWithRelatedWidgetType() {
      return true;
    },
    // ------- GET FIRST QUESTION FROM PAGEWIDGETS ---------
    get getFirstQuestionFromPageWidgets() {
      const firstQuestions = self.widgetsOnPageMaxWithRelatedWidgetType.filter((pw) => pw.widget_type.includes('Question'));
      return firstQuestions.length > 0 && firstQuestions[0];
    }
  }))
  // # actions --> useWidget.jsx
  .actions((self) => ({
    getQuestionWidgetRetries(questionWidget) {
      // Show greyout without feedback / max attempts
      const showNoFeedBackForOneAnswer = self.store?.lessonPlay?.lesson?.attributes['1answer_nofeedback'] || false;
      // Show color without feedback / max attempts 1
      const showVisualFeedbackForOneAnswer = self.store?.lessonPlay?.lesson?.attributes['1answer_visualfeedback'] || false;

      const maxAttempts = showNoFeedBackForOneAnswer || showVisualFeedbackForOneAnswer ? 1 : 2; // Force 1 attempt

      return questionWidget.attributes.widget_type === 'Radio Question' && questionWidget.attributes.options.length === 2 ? 1 : maxAttempts;
    },
    getQuestionWidgetProperties(widgetCode) {
      const { question_widgets: allQuestionWidgets } = self;
      // find questionWidget in lessonPlay by widgetCode
      const questionWidget = allQuestionWidgets.find((lpQw) => lpQw.attributes.widget_code.toLowerCase() === widgetCode.toLowerCase());
      const qwValues = {};
      qwValues.foundWidget = !!questionWidget;
      if (questionWidget) {
        qwValues.qwRetries = self.getQuestionWidgetRetries(questionWidget);
        qwValues.qwWidgetCode = questionWidget.attributes.widget_code;
        qwValues.qwId = questionWidget.id;
        qwValues.qwType = questionWidget.attributes.widget_type;
        qwValues.isQuestion = questionWidget.attributes.widget_type.includes('Question');
      }
      return qwValues;
    },
    // ----> MAP THE LESSON RESULTS TO THE PAGEWIDGET
    getLessonResultQuestionProperties(pageWidget) {
      // find question result by widget id
      const lrQuestion = self.answeredQuestions.find((q) => q.questionId === pageWidget.qwId);
      const lrValues = {};
      lrValues.lrFoundWidget = false;
      lrValues.isCompleted = false;
      if (lrQuestion) {
        lrValues.lrFoundWidget = true;
        lrValues.isCompleted = lrQuestion.correct || lrQuestion.tries >= pageWidget.qwRetries;
        lrValues.lrId = lrQuestion.questionId;
        lrValues.lrTries = lrQuestion.tries;
        lrValues.lrRetries = pageWidget.qwRetries;
        lrValues.lrCorrect = lrQuestion.correct;
        lrValues.lrLast_correct = lrQuestion.last_correct;
      }
      return lrValues;
    },
    // ============= IMPORTANT ==============
    mapPageWidgets2QwAnswers() {
      const hasWidgetsOnPage = self.widgetsOnPageMax.length > 0;
      if (hasWidgetsOnPage) {
        // map data from lesson result with question widget in lesson play
        const pageWidgets = self.widgetsOnPageMax.map((w) => {
          const pageWidgetQuestionProperties = self.getQuestionWidgetProperties(w);
          const pageWidgetAnsweredProperties = self.getLessonResultQuestionProperties(pageWidgetQuestionProperties);
          return { ...pageWidgetQuestionProperties, ...pageWidgetAnsweredProperties };
        });
        return pageWidgets;
      }
      return [];
    }
  }))
  .views(pollWidgetLessonPlayViews)
  .views(wordCloudLessonPlayViews)
  .actions(pollWidgetLessonPlayActions)
  .actions(wordCloudLessonPlayActions)
  .actions((self) => ({
    afterCreate() {
      self.store = getEnv(self).store;
      self.jsonApi = self.store.jsonApi;
      self.lmsApi = self.store.lmsApi;
      if (self.page_id_max === 0) self.lesson_result.attributes.page_id_max = 1;
      self.shuffleQuestionWidget();
      self.pageList = buildLessonPages(self); // Cache pages
      self.checkPagesCompleted();
    },
    shuffleQuestionWidget() {
      const seed = parseInt(self.lesson_result.id.replace(/[^\d]/g, ''), 10);
      self.question_widgets = shuffledQuestionWidgetsArray(self.question_widgets, seed);
      return self.question_widgets;
    },
    setNewArray(newValues) {
      self.allQuestionWidgets = newValues;
      return self.allQuestionWidgets;
    },

    updateWidget: flow(function* updateWidget(widget) {
      delete widget.attributes.widget_link;
      delete widget.attributes.widget_link_uuid;
      // Does the widget exist in lessonplay
      const existingWidgetIndex = self.question_widgets.findIndex((qw) => qw.id === widget.id);
      try {
        // UPDATE WIDGET
        if (existingWidgetIndex >= 0) {
          // QuestionWidget preProcessSnapshot hook replaces attributes with JSON.parse(widget_data)
          // Setting the widget_data with the new values, makes your edit widget changes not disappear
          const feedbackCorrect = widget?.attributes?.feedbackCorrect ? { feedbackCorrect: widget.attributes.feedbackCorrect } : {};
          const feedbackFalse = widget?.attributes?.feedbackFalse ? { feedbackFalse: widget.attributes.feedbackFalse } : {};
          widget.attributes.widget_data = JSON.stringify({
            ...feedbackCorrect,
            ...feedbackFalse,
            question: widget.attributes.question,
            feedback: widget.attributes.feedback,
            options: widget.attributes.options
          });
          //
          applySnapshot(self.question_widgets[existingWidgetIndex], widget);
          self.allQuestionWidgets[existingWidgetIndex] = widget;
          //
          const patchWidget = {
            id: widget.id,
            type: widget.type,
            attributes: {
              title: widget.attributes.title,
              weight: widget.attributes.weight,
              widget_code: widget.attributes.widget_code,
              widget_data: widget.attributes.widget_data,
              widget_type: widget.attributes.widget_type
            },
            relationships: {
              lesson_content_link: widget.relationships.lesson_content_link
            }
          };
          //
          // const widgetEdit = {
          //   ...widget,
          //   attributes: {
          //     ...widget.attributes,
          //     question: undefined,
          //     feedback: undefined,
          //     options: undefined,
          //     created: undefined,
          //     changed: undefined
          //   }
          // };
          //
          yield self.lmsApi.patchpost(patchWidget);
          //
        }
        // NEW WIDGET => 2 actions (with 1ubrequest)
        // 1. Save the new widget
        // 2. Update lesson_content with the saved wiidget
        if (existingWidgetIndex < 0) {
          const {
            id,
            type,
            attributes: { drupal_internal__id, title }
          } = self.lesson_content;
          //
          const lesson_content_widgets = {
            data: [
              ...self.allQuestionWidgets.map((qw) => ({ id: qw.id, type: qw.type })),
              { type: 'question_widget', id: '{{widget.body@$.data.id}}' }
            ]
          };
          //
          const lessonContent = {
            id,
            type,
            attributes: {
              drupal_internal__id,
              title
            },
            relationships: {
              lesson_content_widgets
            }
          };
          // lmsApi.createWidget => subRequest
          // createWidget(questionWidget, lessonContent)
          const response = yield self.lmsApi.createWidget(widget, lessonContent);

          self.question_widgets.push({ ...widget, ...response.widget.data });
          self.shuffleQuestionWidget();
          self.allQuestionWidgets.push({ ...widget, ...response.widget.data });

          self.pageList = buildLessonPages(self);
          self.reload++;
        }
        //
        //
        self.setIsSaving('');
        self.store.showToast({ message: window.i18next.t('widgetSaved'), variant: 'success' });
      } catch (e) {
        if (e.status === 422) {
          log.info('Something is wrong');
          const { product, courseId, lessonId, productName, packageId } = self.store.routerStore.routerState.params;
          self.store.routerStore.goTo('lessonPlay', {
            params: { product, courseId, productName, packageId, lessonId, pageNo: '1' }
          });
        }
        self.setIsSaving('');
        self.store.showToast({ message: window.i18next.t('widgetError'), variant: 'error' });
        log.error(e);
        throw e;
      }
    }),

    deleteWidget: flow(function* deleteWidget(widget) {
      try {
        const {
          id,
          type,
          attributes: { drupal_internal__id, title }
        } = self.lesson_content;
        self.question_widgets = self.question_widgets.filter((qw) => qw.id !== widget.id);
        self.allQuestionWidgets = self.allQuestionWidgets.filter((qw) => qw.id !== widget.id);

        const lesson_content_widgets = {
          data: self.allQuestionWidgets.map((qw) => ({ id: qw.id, type: qw.type }))
        };

        yield self.lmsApi.deleteWidget(widget, {
          id,
          type,
          attributes: { drupal_internal__id, title },
          relationships: { lesson_content_widgets }
        });

        self.pageList = buildLessonPages(self);
        self.reload++;
        self.setIsSaving('');
        self.store.showToast({ message: window.i18next.t('widgetDeleted'), variant: 'success' });
      } catch (e) {
        self.setIsSaving('');
        self.store.showToast({ message: window.i18next.t('widgetError'), variant: 'error' });
        log.error(e);
      }
    }),

    createCmi5Client: flow(function* init() {
      let params = '';
      if (!window.location.href.includes('fetch')) {
        // This is not calling the backend, so no authed client here!
        const resp = yield axios.get('https://localhost:8090/fake-launch', {
          params: {
            course_id: self.lesson.id,
            lms_id: 'fake-lms'
          }
        });

        const { registration_id } = resp.data;
        const { session_id } = resp.data;
        const { agent } = resp.data;

        self.session_id = session_id;
        self.registration_id = registration_id;

        const mockParams = [
          { name: 'fetch', value: 'https://localhost:8090/fetch' },
          { name: 'endpoint', value: 'https://ec2-54-229-30-131.eu-west-1.compute.amazonaws.com/data/xAPI' },
          { name: 'registration', value: registration_id },
          { name: 'activityId', value: `https://e-wise.nl/course/${self.lesson.id}` },
          { name: 'actor', value: JSON.stringify(agent) }
        ];

        params = mockParams.reduce((previousValue, currentValue) => {
          if (previousValue === '') {
            previousValue = '?';
          } else {
            previousValue += '&';
          }
          return `${previousValue}${currentValue.name}=${currentValue.value}`;
        }, '');
      }
      window.history.pushState({ loaded: true }, '', params);

      const cmi5 = new Cmi5();
      yield cmi5.initialize();
      self.cmi5Client = cmi5;
    }),

    getUpdatedFormData(widget) {
      const widgetData = cloneDeep(getSnapshot(widget));
      const hasRelatedWidget = widgetData.attributes?.widget_link;
      // sonarcloud false positive on rgex below
      const uppercaseWidgetType = widgetData.attributes.widget_type.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      widgetData.attributes.widget_type = uppercaseWidgetType;

      if (widgetData.attributes.widget_type.includes('Poll') && hasRelatedWidget) {
        widgetData.attributes.poll_options = ['option1', 'option2'];
        const relatedPoll = self.pollWidgetList.widgets.find((w) => w.nid === widgetData.attributes.widget_link);
        widgetData.attributes.question = relatedPoll.poll.body;
        widgetData.attributes.relPollOptions = cloneDeep(getSnapshot(relatedPoll.poll.options));
      }
      // type: wordcloud
      if (widgetData.attributes.widget_type.includes('Wordcloud')) {
        const wordCloudWidget = self.wordCloudList.widgets.find((w) => w.widgetCode === widgetData.attributes.widget_code);
        const {
          wordcloud: { input_required, show_fields, internal_title }
        } = wordCloudWidget;
        widgetData.attributes = {
          ...widgetData.attributes,
          weight: 0,
          options: [],
          widget_link: wordCloudWidget.nid,
          input_required: input_required === 1,
          show_fields,
          title: 'Word Cloud Question',
          question: internal_title
        };
      }
      return widgetData;
    },

    toggleWidgetMenu(action = 'new', widgetId = '') {
      self.isOpenWm = !self.isOpenWm;
      self.widgetCode = widgetId;
      self.widgetMenuItem.action = action;
      self.widgetMenuItem.widgetId = widgetId;
    },

    setContent(content) {
      const differentCourse = self.lesson_content.attributes.documentTitle !== content.attributes.documentTitle;
      const belowCurrentPage = content.attributes.pages.length < self.pageNo;

      self.setTitle(self.lesson.title || content.attributes.documentTitle);
      self.lesson_content = content;
      self.pageList = buildLessonPages(self);
      if (differentCourse) {
        self.resetLessonToPage(1);
      } else if (belowCurrentPage) {
        self.pageNo = 1;
      }
      self.reload++;
    },

    // page_id_max should always be the furthest visited page (not one past that)
    setPageIdMax() {
      if (self.freeFlow === 'Full') return;
      if (self.pageNo === self.page_id_max + 1) {
        self.lesson_result.attributes.page_id_max += 1;
      }
    },

    checkPagesCompleted() {
      const lr = self.lesson_result;
      // Make sure all pages have a completed property
      // Not full freeflow? Make sure all pages before page_id_max are set as completed
      const lrPagesLength = self.freeFlow === 'Full' && lr.attributes.pages.length ? lr.attributes.pages.length : 0;
      for (let i = lrPagesLength; i < self.pageList.length; i++) {
        lr.attributes.pages[i] = { completed: i < self.page_id_max };
      }
    },

    setSelectedToc(selectedToc) {
      if (selectedToc === -1) {
        const { reverseToc } = self;
        const toc = reverseToc.find((reverseTocItem) => reverseTocItem.pagenr < self.pageNo);
        self.selectedToc = toc ? toc.id : undefined;
      } else {
        self.selectedToc = selectedToc;
      }
    },

    setTocScrollInProgress(isTocScroll) {
      self.isTocScroll = isTocScroll;
    },

    getNeighbourLocation(currentLocation, direction) {
      const { toc } = self.lesson_content.attributes;
      const index = toc.findIndex((item) => item.id === currentLocation);
      if (index > 0) return toc[index + direction].id;
      return null;
    },

    setLocation(location) {
      self.location = location;
    },

    setTitle(title) {
      self.lesson.attributes.title = title;
    },

    setMoveNewPage(newPageNo, locationOnPage) {
      if (locationOnPage !== undefined) self.location = locationOnPage;
      if (typeof newPageNo === 'string') newPageNo = parseInt(newPageNo, 10);
      if (newPageNo === 0) {
        self.pageNo = 0;
        self.lesson.attributes.free_flow = 'Full';
        return false;
      }
      if (newPageNo === self.pageNo || newPageNo < 0 || self.getValidPage(newPageNo) !== newPageNo) return false;

      // Freeflow off situation
      if (self.waitIncreasePageNo && self.freeFlow === 'Off' && newPageNo > self.pageNo) {
        return false; // Don't go to the higher page - wait until forward to current page is handled
      }

      self.prevPage = self.pageNo;
      self.pageNo = newPageNo;
      self.isNextPageEnabled = self.isPageEnabled(self.pageNo + 1);

      // Mark page as completed
      if (!self.waitIncreasePageNo) {
        // Browsing to fast
        const isPageNotSaved = !self.lesson_result.attributes.pages[newPageNo - 1].completed;
        const isFullFreeflowPageCompleted = self.freeFlow === 'Full' && self.questionsAnswered(newPageNo);
        const isPageCompleted = newPageNo === self.page_id_max + 1 && self.questionsAnswered(self.page_id_max);

        if (isPageNotSaved && (isFullFreeflowPageCompleted || isPageCompleted)) {
          self.waitIncreasePageNo = setTimeout(() => {
            self.savePageStatus();
          }, 1000); // Mark page as read after 1 second
        }
      }

      if (self.prevPage === null) return true; // Otherwise page 1 will scroll on initialize
      // Scroll if went to a neighbour page
      if (parseInt(newPageNo, 10) === self.prevPage - 1) {
        // left
        self.setPageMovement({ direction: 'left', center: 'enter-left', left: 'invisible', right: 'leave-right' });
      } else if (parseInt(newPageNo, 10) === self.prevPage + 1) {
        // right
        self.setPageMovement({ direction: 'right', center: 'enter-right', left: 'leave-left', right: 'invisible' });
      } else {
        self.setPageMovement({ direction: '', left: '', right: '', center: '' });
      }
      return true;
    },

    setPageMovement(movement) {
      self.movement.direction = movement.direction;
      self.movement.left = movement.left;
      self.movement.right = movement.right;
      self.movement.center = movement.center;
    },

    saveAnswer: flow(function* saveAnswer(widget, answer) {
      const lr = self.lesson_result;
      const timeStamp = new Date().toISOString();
      const question = self.getLessonResultQuestion(widget);
      const widgetType = widget.attributes?.widget_type?.toLowerCase() || '';
      const widgetCode = widget.attributes?.widget_code?.toLowerCase() || '';
      question.last_correct = answer.correct;
      question.last_response = answer.value;
      question.last_response_timestamp = timeStamp;
      question.tries++;
      if (question.tries === 1) {
        question.correct = answer.correct;
        question.first_response = answer.value;
        question.first_response_timestamp = timeStamp;
        lr.attributes.questions.push(question);
      }

      lr.attributes.questions_answered = lr.attributes.questions.length;
      if (question.tries === self.allowedTries(widget) || question.last_correct) {
        // Get the page number
        const pageNo = self.pageNo === 0 ? 1 : self.pageNo;
        const savePageNo = pageNo - 1;
        // Update current page questions
        self.pageList[savePageNo].questionsAnswered++;
        self.isNextPageEnabled = self.isPageEnabled(self.pageNo + 1);
        // Try to find the same widget on multiple pages and mark it answered on different pages
        // Use case reflection widget is coming back on different pages with the feedback
        const findSameWidget = self.pageList
          .map((w, index) => ({ index, widgetCodes: w.widgetCodes }))
          .filter((obj) => obj.widgetCodes.some((code) => code.toLowerCase() === widgetCode.toLowerCase()) && obj.index !== savePageNo)
          .map((obj) => obj.index);
        // Loop through findSameWidget and perform some action for each index
        if (findSameWidget) {
          findSameWidget.forEach((index) => {
            self.pageList[index].questionsAnswered++;
          });
        }
        if (process.env.REACT_APP_CMI5_ENABLED === 'true') {
          self.saveCmi5Answer(widget, question, answer);
        }
      }
      if (widgetType && widgetType.includes('open question')) {
        self.setReflectionList({ id: widgetCode, time: new Date().getTime() });
      }

      yield self.savePageStatus(true);
    }),

    finishLesson: flow(function* finishLesson(nid) {
      try {
        const response = yield self.lmsApi.postFinish(nid);
        applySnapshot(self.lesson_result, response.data.lesson_result);
        self.courseResult = response.data.course_result;
        return response.data;
      } catch (e) {
        self.store.setLessonState('error', e);
        throw e;
      }
    }),

    finishCourse: flow(function* finishCourse(nid) {
      try {
        const response = yield self.lmsApi.finishCourseResult(nid);
        const totalCompletedCourses = response.data?.meta?.user_courses_finished;
        if (totalCompletedCourses) {
          self.store.setTotalCoursesCompleted(totalCompletedCourses);
        }

        return response.data;
      } catch (e) {
        self.store.setLessonState('error', e);
        throw e;
      }
    }),

    retrieveEvaluation: flow(function* retrieveEvaluation(uuid, cuuid) {
      try {
        const response = yield self.lmsApi.fetchEvaluationByUser(uuid, cuuid);
        return response.data.meta.count > 0;
      } catch (e) {
        return false;
      }
    }),

    // params = widget, question, answer
    saveCmi5Answer({ attributes }, { questionId }, answer) {
      if (!self.cmi5Client.isAuthenticated) return;

      createCmi5JourneyHooks(self.cmi5Client).onAnswerQuestion(
        self.lesson.id,
        questionId,
        answer.value,
        attributes.question,
        answer.correct,
        attributes.options,
        attributes.widget_type
      );
    },

    savePageStatus: flow(function* savePageStatus(again = false) {
      const lr = self.lesson_result;
      const pageNo = self.pageNo === 0 ? 1 : self.pageNo;
      self.setPageIdMax();
      if (self.canPageBeCompleted(self.pageNo)) lr.attributes.pages[pageNo - 1].completed = true;
      self.isNextPageEnabled = self.isPageEnabled(self.pageNo + 1);
      self.waitIncreasePageNo = null;
      if (again) log.debug(`Again doing save page${self.page_id_max}`);

      const data = {
        id: lr.id,
        type: lr.type,
        attributes: {
          questions_answered: lr.attributes.questions_answered,
          page_id_max: lr.attributes.page_id_max,
          data: JSON.stringify({ pages: lr.attributes.pages.toJSON(), questions: lr.attributes.questions.toJSON() })
        }
      };

      try {
        // TODO: Save lesson_result to the backend
        const result = yield self.lmsApi.patchpost(data);
        applySnapshot(self.lesson_result, result);
        // yield timeout(5);
        // data = JSON.stringify(processLessonResult(getSnapshot(lr)));
        // localStorage.setItem('lessonResult', data);
        // applySnapshot(self.lesson_result, JSON.parse(data));
        log.debug(`After saving page ${self.page_id_max}`);
      } catch (e) {
        if (e.status === '422') {
          log.error(e);
          self.store.showToast({ message: window.i18next.t('widgetErrorHelpDesk'), variant: 'error' });
          throw e;
        }
        if (!again) {
          log.error('Error during updating page status');
          self.savePageStatus(true); // try one more time
        } else {
          if (self.canPageBeCompleted(self.pageNo)) lr.attributes.pages[self.pageNo - 1].completed = false;
          self.pageNo = self.getValidPage(self.pageNo);
          log.error('Cannot increase page in lesson result on the server', e); // Mismatch with the backend if it went wrong twice
        }
        self.store.showToast({ message: window.i18next.t('widgetError'), variant: 'error' });
        log.error(e);
        throw e;
      }
    }),

    resetLessonResult: flow(function* resetLessonResult(page) {
      if (!self.store.canEditCourse) return;
      try {
        const result = yield self.lmsApi.resetLessonResult(self.lesson_result.attributes.drupal_internal__id, page);
        const lessonResult = result.data.lesson_result;
        // Initialize data (preProcessSnapshot does not process it otherwise)
        lessonResult.attributes.data = JSON.stringify({
          pages: self.lesson_content.attributes.pages.toJSON().map((value, idx) => ({
            completed: idx < lessonResult.attributes.page_id_max
          })),
          questions: []
        });
        applySnapshot(self.lesson_result, lessonResult);
        self.store.showToast({
          message: 'The results have been resetted!',
          variant: 'success'
        });
      } catch (e) {
        self.store.showToast({
          message: 'Oops! Something went wrong with resetting your results.',
          variant: 'error'
        });
        log.error('Cannot reset lesson result on the server', e);
      }
    }),

    resetLessonToPage: flow(function* resetLessonToPage(newPageNo = 1) {
      const { store } = self;
      self.pageNo = newPageNo;
      const { product, courseId, lessonId, productName, packageId } = store.routerStore.routerState.params;
      store.routerStore.goTo('lessonPlay', {
        params: { product, courseId, lessonId, productName, packageId, pageNo: newPageNo.toString() }
      });
      if (newPageNo === 0) {
        self.lesson.attributes.free_flow = 'Full';
        return;
      }
      const lr = self.lesson_result;
      const data = {
        id: lr.id,
        type: lr.type,
        attributes: {
          questions: [],
          page_id_max: 2,
          pages: [],
          questions_answered: 0
        }
      };
      try {
        self.lesson_result.attributes.page_id_max = newPageNo;
        // TODO: talk to jsonApi
        const result = yield self.lmsApi.patchpost(data);
        applySnapshot(self.lesson_result, result);
        // yield timeout(5);
        // data = JSON.stringify(processLessonResult(getSnapshot(lr)));
        // localStorage.setItem('lessonResult', data);
        // applySnapshot(self.lesson_result, JSON.parse(data));

        self.pageList = buildLessonPages(self); // Cache pages
        self.checkPagesCompleted();
        self.isNextPageEnabled = self.isPageEnabled(newPageNo + 1);
      } catch (e) {
        log.error('Cannot reset lesson to page on the server', e);
      }
    }),

    // Only used for Cypress testing
    setTest: flow(function* setTest({ freeFlow, resetToPage }) {
      try {
        if (resetToPage && resetToPage !== self.page_id_max) {
          yield self.jsonApi.self.resetLessonToPage(resetToPage);
        }
        if (freeFlow && freeFlow !== self.freeFlow) {
          self.lesson.attributes.free_flow = freeFlow;
          const lessonData = cloneDeep(getSnapshot(self.lesson));
          // TODO: talk to json Api
          yield self.lmsApi.patchpost(lessonData);
          applySnapshot(self.lesson, lessonData);
        }
      } catch (e) {
        log.error('Cannot reset lesson to page on the server', e);
      }
    }),
    postAskQuestion: flow(function* postAskQuestion(userQuestion) {
      const professionId = self.store.profession.uuid;
      const { course, city, houseNumber, mobilePhone, question, street, zipcode } = userQuestion;
      const questionData = {
        data: {
          type: 'user_question',
          attributes: {
            title: course.name,
            city,
            destination: null,
            housenumber: houseNumber,
            mobile_phone: mobilePhone,
            question,
            street,
            zipcode
          },

          relationships: {
            about_course: {
              data: {
                type: 'course',
                id: course.id
              }
            },
            profession: {
              data: {
                type: 'profession',
                id: professionId
              }
            }
          }
        }
      };
      return yield self.lmsApi.postAskQuestion(questionData);
    }),
    // This will push the reflection widget to an array
    setReflectionList(widgetCode) {
      // Check if widgetCode is not already in the array
      if (!self.reflectionList.includes(widgetCode)) {
        // If not, push it into the array
        self.reflectionList.push(widgetCode);
      }
      // Return the updated array
      return self.reflectionList;
    },
    setIsSaving(saving) {
      self.isSaving = saving;
    }
  }));

// eslint-disable-next-line no-unused-vars
export async function getLessonPlay(store, courseId, lessonId) {
  //
  if (store.lessonPlay && store.lessonState !== 'init') return store.lessonPlay;
  log.debug(`Running fetchLesson. State: ${store.lessonState}`);
  //
  if (store.lessonState === 'pending' || store.lessonState === 'done') return null; // wait for running fetch
  // set state to pending
  store.setLessonState('pending');
  // Check if the course is failed
  const isFailed = isCourseFailed(store?.lesson_results, store?.courseResults, Number(courseId));

  try {
    const play = isFailed
      ? await store.lmsApi.restartLessonPlay(courseId, lessonId) // /start?restart=true (restartLessonPlay)
      : await store.lmsApi.fetchLessonPlay(courseId, lessonId); // /start

    if (play.errorCode !== undefined && play.errorCode !== null) {
      store.setLessonState('error', play);
      if (play.errorCode === 'dependingCourse') {
        store.setLessonDependsOnCourse(play);
      }
      return null;
    }

    /**
     * Workaround for the toc
     * This workaround prevents mobx errors as it expects the toc to be an array
     */
    // Check if the toc is an array || if not, make it an empty array
    if (!Array.isArray(play?.lesson_content?.attributes?.toc)) {
      play.lesson_content.attributes.toc = [];
    }

    log.debug('After fetch');

    // Create LessonPlay store
    try {
      store.setLessonPlay(LessonPlay.create(play, { store }));
      store.setLessonState('done');
      // CMI5 client
      if (process.env.REACT_APP_CMI5_ENABLED === 'true') {
        store.lessonPlay.createCmi5Client();
      }
      store.lessonPlay.setNewArray(play.question_widgets);
      return null;
    } catch (err) {
      log.debug(err);
      Sentry.captureException(`MobX error: Something went wrong with course: ${courseId} lesson: ${lessonId}`);
      Sentry.captureException(err);
      store.setLessonState('pending');
      return null;
    }
  } catch (e) {
    store.setLessonState('error', e);
    if (e.modelErrors) {
      e.modelErrors.forEach(log.warn);
    }
    if (e.status === 401 || e.status === 403) {
      log.info('No access to this lesson');
      store.gotoErrorPage({ title: 'Error loading lesson', message: 'No access', statuscode: e.status.toString() });
      return null;
    }
    throw e;
  }
}
