/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import { getSnapshot } from 'mobx-state-tree';

export const convertIsoDate2Date = (isoDate, format = 'YYYY-MM-DD') => {
  const date = new Date(isoDate);
  return dayjs(date).format(format);
};
export const convertEpochTimestamp = (epochTime, format = 'YYYY-MM-DD') => dayjs.unix(epochTime).format(format);

// ___ DASHBOARD ____________________________________________________
// Draft courses: toasts with translations
export const ToastDraftCourses = () => {
  const { t } = useTranslation('adminCourses');
  return {
    success: { title: t('draftCourses.delete.success.title'), content: t('draftCourses.delete.success.message'), type: 'success' },
    error: { title: t('draftCourses.delete.error.title'), content: t('draftCourses.delete.error.message'), type: 'error' },
    default: { timeOut: 4000, variant: 'filled' }
  };
};
// Linked courses: toasts with translations
export const ToastLinkedCourses = () => {
  const { t } = useTranslation('adminCourses');
  return {
    success: { title: t('linkedCourses.delete.success.title'), content: t('linkedCourses.delete.success.message'), type: 'success' },
    error: { title: t('linkedCourses.delete.error.title'), content: t('linkedCourses.delete.error.message'), type: 'error' },
    default: { timeOut: 4000, variant: 'filled' }
  };
};

// ___ TAB INTRODUCTION/IMAGES/SHARING _____________________________________________
export const EditCourseDefaultValues = (store) => {
  const { courseEdit } = store;
  const { currentCourse, dependentCourse } = courseEdit;

  const {
    attributes: {
      title,
      status,
      online,
      field_points,
      field_extra_points,
      field_accreditation_code,
      field_valid_period,
      course_status,
      field_extra_points_desc,
      expected,
      field_skip_evaluation
    },
    categories,
    categories2,
    specialAccess,
    type: courseType,
    id: courseId,
    relationships: { lesson_container }
  } = currentCourse;

  const defaultValues = {
    course: {
      type: courseType,
      id: courseId,
      attributes: {
        status,
        title: title || '',
        online: online ? '1' : '0',
        expected,
        course_status: course_status || 'Default',
        field_points: field_points || '',
        field_extra_points: field_extra_points || '',
        field_extra_points_desc: field_extra_points_desc || '',
        field_valid_period: {
          value: field_valid_period?.value || null,
          end_value: field_valid_period?.end_value || null
        },
        field_accreditation_code: field_accreditation_code || '',
        field_skip_evaluation
      },
      relationships: {
        special_access: specialAccess,
        course_category: categories,
        course_category2: categories2
      }
    },

    lessonContainer: {
      type: lesson_container?.data?.id?.type,
      id: lesson_container?.data?.id?.id,
      attributes: {
        field_goals: lesson_container?.data?.id?.attributes?.field_goals?.value || '',
        field_extra_label: lesson_container?.data?.id?.attributes?.field_extra_label || '',
        field_information: lesson_container?.data?.id?.attributes?.field_information?.value || '',
        field_podcast_html: lesson_container?.data?.id?.attributes?.field_podcast_html?.value || '',
        podcast_title: lesson_container?.data?.id?.attributes?.podcast_title || '',
        field_preview_video: lesson_container?.data?.id?.attributes?.field_preview_video?.value || '',
        field_keywords: lesson_container?.data?.id?.attributes?.field_keywords?.split(',') || [],
        field_sharing_info: lesson_container?.data?.id?.attributes?.field_sharing_info || ''
      },
      relationships: {
        dependent_courses: dependentCourse,
        field_authors: lesson_container?.data?.id?.authors,
        field_reviewers: lesson_container?.data?.id?.reviewers,
        field_course_image_extern: lesson_container?.data?.id?.field_course_image_extern,
        field_course_header_photo: lesson_container?.data?.id?.field_course_header_photo,
        field_coursephoto: lesson_container?.data?.id?.field_coursephoto
      }
    }
  };

  return defaultValues;
};

export function editCourseModel(values) {
  return {
    course: {
      ...values.course,
      attributes: {
        ...values.course.attributes,
        field_valid_period: {
          value:
            values.course.attributes.field_valid_period?.value && convertIsoDate2Date(values.course.attributes.field_valid_period.value),
          end_value:
            values.course.attributes.field_valid_period?.end_value &&
            convertIsoDate2Date(values.course.attributes.field_valid_period.end_value)
        }
      },
      relationships: {
        ...values.course.relationships,
        course_category: {
          data: values.course.relationships.course_category
        },
        course_category2: {
          data: values.course.relationships.course_category2
        },
        special_access: {
          data: values.course.relationships.special_access
        }
      }
    },
    lessonContainer: {
      ...values.lessonContainer,
      attributes: {
        ...values.lessonContainer.attributes,
        field_keywords: values.lessonContainer.attributes.field_keywords.toString()
      },
      relationships: {
        ...values.lessonContainer.relationships,
        field_authors: {
          data: values.lessonContainer.relationships.field_authors
        },
        field_reviewers: {
          data: values.lessonContainer.relationships.field_reviewers
        },
        dependent_courses: {
          data:
            !Object.is(values.lessonContainer.relationships.dependent_courses, null) &&
            Object.keys(values.lessonContainer.relationships.dependent_courses).length > 0
              ? [values.lessonContainer.relationships.dependent_courses]
              : []
        },
        field_course_image_extern: {
          data: values.lessonContainer.relationships.field_course_image_extern
        },
        field_course_header_photo: {
          data: values.lessonContainer.relationships.field_course_header_photo
        },
        field_coursephoto: {
          data: values.lessonContainer.relationships.field_coursephoto
        }
      }
    }
  };
}
// TAB INTRODUCTION/IMAGES/SHARING > FORM SAVE
// toast messages with translation
export const Toast = () => {
  const { t } = useTranslation('editCourseForm');
  const toast = {
    success: { title: t('editCourse.updated.success.title'), content: t('editCourse.updated.success.message'), type: 'success' },
    error: { title: t('editCourse.updated.fail.title'), content: t('editCourse.updated.fail.message'), type: 'error' },
    default: { timeOut: 4000, variant: 'filled' }
  };
  return toast;
};

// ___ TAB LESSON ___________________________________________________________________
// helper function to get the lesson content from the lesson
export const getLessonContent = (lesson, included) => {
  const lessonContentId = lesson.relationships.lesson_content?.data?.id;
  if (!lessonContentId) return false;
  const lessonContent = included.find((item) => item.id === lessonContentId);
  return lessonContent || false;
};
// helper function to get total widgets from the lesson
export const getTotalWidgets = (lesson, included, lessonWidgets) => {
  const lessonContent = getLessonContent(lesson, included);
  if (!lessonContent) return 0;
  const widgets = lessonWidgets?.[lessonContent.id] || lessonContent.relationships.lesson_content_widgets.data.length;
  return widgets;
};
// helper function returns if the lesson has a tagdoc
export const isOdtFile = (lesson, included) => {
  const lessonContent = getLessonContent(lesson, included);
  const _lessonContent = JSON.parse(JSON.stringify(lessonContent));
  if (!lessonContent) return false;
  return _lessonContent.relationships.tagdoc?.data?.id;
};

// TAB LESSON > ADD/EDIT LESSON DIALOG ________________________________________________
// -- FORM
// -- helper to create the initial values for the lesson form
export const getSelectedLesson = (store) => {
  // Destructering
  const { courseEdit } = store;
  const { dialogLesson } = courseEdit;
  const { lessonId } = dialogLesson;
  const {
    currentCourse: { included }
  } = courseEdit;

  // FIND SELECTED LESSON in included
  return (lessonId && included.find((item) => item.id === lessonId)) || false;
};
// -- helper to create the initial values for the lesson form
export const getSelectedLessonContent = (currentCourse, selectedLesson) => {
  const defaultLessonContent = {
    type: 'lesson_content',
    attributes: { title: `LC ${currentCourse.attributes.title}` },
    relationships: { lesson_content_widgets: { data: null }, tagdoc: { data: null } }
  };
  // Does the lesson have a lesson_content?
  const isLessonContentId = selectedLesson?.relationships?.lesson_content?.data?.id;
  // FIND LESSON CONTENT in included
  const isLessonContent = (isLessonContentId && currentCourse.included.find((item) => item.id === isLessonContentId)) || false;
  // LESSON CONTENT  = FOUND LESSON_CONTENT or else defaultLessonContent
  return (isLessonContent && JSON.parse(JSON.stringify(isLessonContent))) || defaultLessonContent;
};
// -- FORM INITIAL VALUES: returns the initial values for the lesson form
export const getSelectedLessonInitialFormValues = (currentCourse, selectedLesson, lessonDefaultValues, selectedLessonContent) => {
  // SET INITIAL VALUES
  let initialValues = (selectedLesson && cloneDeep(getSnapshot(selectedLesson))) || lessonDefaultValues;

  // CONVERT null to 0 @ fields: max_attempts &  minimum_score_2nd_try
  const setAttributes = {
    ...initialValues.attributes,
    ...{
      '1answer_nofeedback': initialValues.attributes['1answer_nofeedback'] || false,
      '1answer_visualfeedback': initialValues.attributes['1answer_visualfeedback'] || false,
      rotating_answers: initialValues.attributes.rotating_answers || false,
      title: initialValues.attributes.title || `lesson ${currentCourse.attributes.title}`,
      max_attempts: initialValues.attributes.max_attempts || 0,
      minimum_score_2nd_try: initialValues.attributes.minimum_score_2nd_try || 0
    }
  };
  //
  selectedLessonContent.attributes.title = selectedLessonContent?.id ? initialValues.attributes.title : `LC ${setAttributes.title}`;
  //
  initialValues = { ...initialValues, ...{ attributes: setAttributes }, ...{ selectedLessonContent } };
  //
  return initialValues;
};
// -- FORM FIELD LABELS with translations
export const LessonFormLabels = () => {
  const { t } = useTranslation('editCourseForm');
  return {
    title: t('lesEditForm.title'),
    lessonType: t('lesEditForm.lessonType'),
    freeFlow: t('lesEditForm.freeFlow'),
    rotatingAnswers: t('lesEditForm.rotatingAnswers'),
    firstAnswerNofeedback: t('lesEditForm.firstAnswerNoFeedback'),
    firstAnswerVisualfeedback: t('lesEditForm.firstAnswerVisualFeedback'),
    minimumScore: t('lesEditForm.minimScore'),
    minimumScore2ndTry: t('lesEditForm.minimumScore2ndTry'),
    maxAttempts: t('lesEditForm.maxAttempts')
  };
};
// -- FORM SAVE RESULT TOAST with translations
export const LessonToast = () => {
  const { t } = useTranslation('editCourseForm');
  const toast = {
    success: { title: t('lesEditForm.save.success.title'), content: t('lesEditForm.save.success.message'), type: 'success' },
    error: { title: t('lesEditForm.save.error.title'), content: t('lesEditForm.save.error.message'), type: 'error' },
    default: { timeOut: 100000, variant: 'filled' }
  };
  return toast;
};

// -- QUESTION WIDGETS TABLE
// -- helper function: returns all button placeholders from lesson html (for example [[widgetcode]] )
export const getPlaceHoldersFromLesson = (str) => {
  if (!str) return [];
  const regex = /\[.*?\]]/gm;
  if (!str.match(regex)) return [];
  // get placeholders from the html
  const buttons = str.match(regex).filter((item) => !item.includes('pagebreak'));
  const qButtons = buttons.map((item) => ({
    type: 'question_widget',
    attributes: {
      status: true,
      title: '',
      weight: 1,
      widget_code: item.replace('[[', '').replace(']]', ''),
      widget_data: '{}',
      widget_type: ''
    }
  }));
  return qButtons;
};
// -- helper
export const restButtonsInLesson = (widgets, buttonsInLesson) => {
  const widgetsCodes = widgets.map((item) => item.attributes.widget_code);
  // FILTER the QuestionButtons array to exclude any buttons that are already in the widgets array
  const restButtons = buttonsInLesson.filter((item) => !widgetsCodes.includes(item.attributes.widget_code));
  return restButtons;
};
// -- QUESTION WIDGETS TABLE CONFIG: returns the table config (options + columns)
export const lessonTableQuestionWidgets = {
  options: {
    grouping: true,
    searchFieldVariant: 'outlined',
    pageSize: 25,
    emptyRowsWhenPaging: false,
    search: true,
    showTitle: true,
    header: true,
    toolbar: true,
    dense: true,
    detailPanelType: 'single',
    pageSizeOptions: [5, 10, 25, 50, 100]
  },
  columns: [
    { title: 'nid', field: 'attributes.drupal_internal__nid' },
    { title: 'type', field: 'attributes.widget_type', cellStyle: { whiteSpace: 'nowrap' } },
    { title: 'code', field: 'attributes.widget_code', defaultSort: 'asc', cellStyle: { whiteSpace: 'nowrap' } },
    { title: 'title', field: 'attributes.title', cellStyle: { width: '100%', whiteSpace: 'nowrap' } },
    { title: 'weight', field: 'attributes.weight' },
    { title: 'status', field: 'attributes.status' }
  ]
};

// TAB FEEDBACK ___________________________________________________________________
export const feedbackTableOptions = {
  toolbar: false,
  debounceInterval: 1000,
  emptyRowsWhenPaging: false,
  filtering: true,
  isLoading: true,
  padding: 'dense',
  pageSize: 20,
  pageSizeOptions: [5, 10, 20, 50],
  paginationType: 'stepped',
  selection: false,
  maxColumnSort: 1,
  rowStyle: { fontSize: '14px', '&:hover': { background: 'silver' } }
};
