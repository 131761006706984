/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { useStore } from 'hooks';

/* MUI */
import Avatar from '@mui/material/Avatar';
import { Chip, Box, LinearProgress } from '@mui/material';
/* MATERIAL TABLE */
import MaterialTable, { MTableToolbar } from '@material-table/core';
/* ICONS */
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CheckIcon from '@mui/icons-material/Check';
/* Custom */
import useEwapi from '../../hooks/useEwapi';
import { dateDiff, convertDate } from '../../helpers/utils';

/*
The primary purpose of this component is to provide users with a table of courses within a selected package, 
allowing them to choose a course for advanced actions like reuse, duplication, or linking. 
Users can click on a course to select it, and the selected course is highlighted in the table.
*/
const AdvancedCourseTables = ({ handleCopyActions, containerNid, title, copyValues }) => {
  const store = useStore();
  const { t } = useTranslation('adminCourses');
  const theme = useTheme();

  const listType = copyValues?.listType || 1;
  // test
  const ewapiCourseList = `editor-course-list/${containerNid}/${listType}/json`;
  // HOOK
  const { response, loading, error } = useEwapi(ewapiCourseList, 'courses');
  // STATE
  // const [selectedRow, setSelectedRow] = useState(null);
  // Table options
  const options = {
    actionsColumnIndex: -1,
    selection: false,
    filtering: false,
    grouping: false,
    searchFieldVariant: 'outlined',
    columnsButton: true,
    padding: 'dense',
    debounceInterval: 500,
    pageSize: 10,
    pageSizeOptions: [5, 10, 25, 50, 100],
    emptyRowsWhenPaging: false
  };

  // EVENTS
  const handleClick = (event, rowData) => {
    handleCopyActions({
      ...copyValues,
      courseUuid: rowData.course_uuid,
      course_nid: rowData.course_nid,
      course: rowData.course_title
    });
  };

  const isPublished = {
    0: (
      <Chip
        key="unpublished"
        label="Unpublished"
        size="small"
        sx={{ fontSize: '10px', height: '22px', border: '2px solid red', background: 'white' }}
      />
    ),
    1: (
      <Chip
        key="published"
        label="Published"
        size="small"
        sx={{ fontSize: '10px', height: '22px', border: '2px solid orange', background: 'white', color: 'black' }}
      />
    ),
    Default: ''
  };
  const sxAvatar = { width: 22, height: 22, color: 'white', borderRadius: '50%' };
  const isOnline = {
    0: (
      <Avatar key="off" sx={{ ...sxAvatar, bgcolor: 'red' }}>
        <CloudOffIcon sx={{ fontSize: '15px' }} />
      </Avatar>
    ),
    1: (
      <Avatar key="on" sx={{ ...sxAvatar, bgcolor: theme.palette.secondary.light }}>
        <CheckIcon sx={{ fontSize: '15px' }} />
      </Avatar>
    ),
    Default: ''
  };

  const linked = (courseContainers) => {
    if (courseContainers.length === 0) return null;
    const containers = courseContainers.map((container) => (
      <Chip
        id={container.course_nid}
        sx={{ fontSize: '10px', height: 'auto', padding: `1px 2px` }}
        color="secondary"
        key={container.course_container_title}
        label={container.course_container_title}
        size="small"
      />
    ));
    return containers;
  };

  // RENDERING
  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }
  if (error) return 'error loading courses...';

  let courseCount = 'Retrieving';
  let tableData = [];

  const { courses } = response?.data || [];
  // filter courses that are not in the current course_container
  const filteredCourses = courses?.filter(
    (course) => !course.course_container?.find((container) => container.course_container_nid === +store.params.containerNid)
  );

  tableData = filteredCourses.map((course) => ({
    id: course.course_nid,
    course_nid: course.course_nid,
    course_uuid: course.course_uuid,
    course_title: course.course_title,
    course_status: course.course_status,
    course_online: course.course_online,
    course_validFrom: course.valid_period,
    course_validEnd: course.valid_period_end,
    course_points: course.course_points,
    course_container: course.course_container
  }));
  courseCount = tableData.length;

  return (
    <Box sx={{ flexGrow: 1, marginTop: '16px' }} className="draft-view admin-custom-page">
      <Box className="advanced-course-tables" sx={{ 'table thead': { textTransform: 'capitalize' } }}>
        <MaterialTable
          isLoading={loading}
          title={`${title} ${t('dashboard.courses')}: ${courseCount} `}
          columns={[
            {
              title: 'nid',
              field: 'id'
            },
            {
              title: t('dashboard.course'),
              field: 'course_title',
              cellStyle: { width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
            },
            { title: 'pnt', field: 'course_points' },
            { title: t('dashboard.till'), field: 'course_validEnd', render: (rowData) => convertDate(rowData.course_validEnd) },
            { title: t('dashboard.daysLeft'), field: 'course_validEnd', render: (rowData) => dateDiff(rowData.course_validEnd) },
            { title: 'linked', field: 'course_container', render: (rowData) => linked(rowData.course_container) },
            { title: 'status', field: 'course_status', render: (rowData) => isPublished[rowData.course_status] },
            { title: 'online', field: 'course_online', render: (rowData) => isOnline[rowData.course_online] }
          ]}
          data={tableData}
          // eslint-disable-next-line no-shadow
          onRowClick={(evt, selectedRow) => handleClick(evt, selectedRow)}
          options={{
            ...options,
            ...{
              rowStyle: (rowData) => ({
                backgroundColor: copyValues.course_nid === rowData.course_nid ? theme.palette.primary.light : 'transparent',
                color: copyValues.course_nid === rowData.course_nid ? 'white' : 'black'
              })
            }
          }}
          localization={{ toolbar: { searchPlaceholder: t('dashboard.search') } }}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
              </div>
            )
          }}
        />
      </Box>
    </Box>
  );
};

AdvancedCourseTables.propTypes = {
  containerNid: PropTypes.any,
  title: PropTypes.any,
  handleCopyActions: PropTypes.func,
  copyValues: PropTypes.object
};

export default observer(AdvancedCourseTables);
