import { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
// MUI components
import { Box, Grid, Typography, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SchoolIcon from '@mui/icons-material/School';
// Custom components
import TopLinks from 'pages/course/components/CourseOverview/TopLinks';
import TeacherSearch from './components/TeacherSearch';
import TeacherCard from './components/TeacherCard';

const TeachersOverview = () => {
  const store = useStore();
  const { t } = useTranslation();
  const courses = store?.courses?.courses || [];
  const advisoryBoardMembers = store?.courses?.advisoryBoardMembers;
  const onlyTeachers = store?.courses?.onlyTeachers;
  const [searchText, setSearchText] = useState('');

  const displayTeachers = searchText
    ? onlyTeachers.filter((teacher) => teacher.fullName.toLowerCase().includes(searchText.toLowerCase()))
    : onlyTeachers;

  if (!courses.length) {
    return <CircularProgress color="inherit" size={20} />;
  }

  if (!onlyTeachers.length && !advisoryBoardMembers.length) {
    return (
      <Box className="teacherOverview">
        <TopLinks current="teachersOverview" />
        <Box className="teachers_overview_box">
          <TeacherSearch placeholder={t('teachers.search')} onChange={setSearchText} />
          <Box className="noTeachers">
            <IconButton aria-label="add to favorites" className="school_icon">
              <SchoolIcon color="primary" />
            </IconButton>
            <Typography color="primaryBlue.main" variant="h1" fontSize="16px">
              {t('teachers.noTeachers', 'No teachers available')}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="teacherOverview">
      <TopLinks current="teachersOverview" />
      <Box className="teachers_overview_box">
        <TeacherSearch placeholder={t('teachers.search')} onChange={setSearchText} />
        {advisoryBoardMembers?.length > 0 && (
          <>
            <Typography variant="h5" sx={{ mt: 3, color: 'primary.main' }}>
              {t('teachers.title1')}
            </Typography>
            <Grid container spacing={2} sx={{ mt: -2 }}>
              {advisoryBoardMembers.map(({ id, uuid, fullName, img }) => (
                <Grid container item key={id} xs={12} sm={6} md={4} lg={3} xl={2}>
                  <TeacherCard teacherId={uuid} img={img} title={fullName} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {displayTeachers?.length > 0 && (
          <>
            <Typography variant="h5" sx={{ mt: 3, color: 'primary.main' }}>
              {t('teachers.title2')}
            </Typography>
            <Grid container spacing={2} sx={{ mt: -2 }}>
              {displayTeachers.map(({ id, uuid, fullName, img }) => (
                <Grid container item key={id} xs={12} sm={6} md={4} lg={3} xl={2}>
                  <TeacherCard teacherId={uuid} img={img} title={fullName} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {displayTeachers?.length === 0 && (
          <Box className="noTeachers">
            <IconButton aria-label="add to favorites" className="school_icon">
              <SchoolIcon color="primary" />
            </IconButton>
            <Typography color="primaryBlue.main" variant="h1" fontSize="16px">
              {t('teachers.noTeachers', 'No teachers available')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default observer(TeachersOverview);
