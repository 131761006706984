import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Card, CardActions, CardContent, Divider, Grid, SvgIcon, Typography } from '@mui/material';
import download from 'components/icons/download';
import { differenceInMinutes, isValid } from 'date-fns';
import { ThemeBrandTitle } from 'helpers/Helpers';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import CoursePointsAlert from 'pages/course/components/CourseIntroduction/CoursePointsAlert';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { urlify } from 'state/Utils';
import Header from './components/Header';
import LtiUserForm from './forms/LtiUserForm';

const isCourseCompletedInLessThan30Minutes = (date1, date2) => {
  if (!isValid(date1) || !isValid(date2)) {
    return false;
  }

  const diff = differenceInMinutes(date2, date1);
  return diff < 30;
};

const LastStep = () => {
  const store = useStore();
  const { t } = useTranslation('evaluation');
  const [link, setLink] = useState('');
  const [disabled, setDisabled] = useState(true);
  const { courseId } = store.routerStore.routerState.params;
  const courseTitle = store.findCourseByNid(Number(courseId)).course_title;
  const courseResultsId = store.lessonPlay?.lesson_result?.attributes?.course_result;
  const brandTitle = ThemeBrandTitle();
  const { u, uw, kunt } = brandTitle !== 'E-WISE' ? { u: 'U', uw: 'uw', kunt: 'kunt' } : { u: 'Je', uw: 'je', kunt: 'kan' };
  // const score = store.lessonPlay?.lesson_result?.attributes?.score;
  const [answersPdf, setAnswersPdf] = useState('');
  const isLti = store.getLtiSession() === 1 || store.getLtiSession() === '1';
  const isAccredited = store.selectedPackage?.accredited === 1;
  const isNotariaatPackage = store.selectedPackageTitle.toLowerCase().includes('notariaat');
  const hasRbNumberField = store.profile?.attributes && 'field_rbnr' in store.profile?.attributes;
  const isMicrolearning = store.selectedPackage?.microlearning;

  // show popup if course is completed in less than 30 minutes only for notariaat package
  const [showPopup, setShowPopup] = useState(() => {
    if (
      isNotariaatPackage &&
      isAccredited &&
      isCourseCompletedInLessThan30Minutes(
        new Date(store.lessonPlay?.lesson_result?.attributes.created),
        new Date(store.lessonPlay?.lesson_result?.attributes.changed)
      )
    ) {
      return true;
    }
    return false;
  });

  const [openLtiForm, setOpenLtiForm] = useState(false);

  const handleCloseLtiForm = () => {
    setOpenLtiForm(false);
  };

  const loadCompleted = () => {
    store.setLessonState('init');
    if (!isLti && !isMicrolearning) {
      setTimeout(() => {
        store.setLessonPlay(null);
      }, 1450);
      store.routerStore.goTo('defCourseIntroduction', {
        params: {
          product: store.params.product,
          productName: store.params.productName,
          packageId: store.params.packageId,
          courseId: store.params.courseId,
          title: urlify(courseTitle)
        }
      });
    }
    if (!isLti && isMicrolearning) {
      setTimeout(() => {
        store.setLessonPlay(null);
      }, 1450);
      store.routerStore.goTo('courseOverview', {
        params: {
          product: store.params.product,
          productName: store.params.productName,
          packageId: store.params.packageId
        }
      });
    }
  };

  useEffect(() => {
    if (!link) {
      const fetchCertificate = async () => {
        try {
          const completedCourse = await store.lessonPlay.finishCourse(courseResultsId);
          const courseResultUuid = store.lessonPlay.courseResult.id;
          const code = await store.lmsApi.downloadCertificate(courseResultUuid);
          const getAnswerPdf = completedCourse?.data?.answers_pdf?.url || '';
          if (getAnswerPdf) {
            setAnswersPdf(getAnswerPdf);
          }
          setLink(`${process.env.REACT_APP_JSONAPI}/course-result/${courseResultUuid}/${code}/certificate`);
          setDisabled(null);
        } catch (error) {
          console.error('Er is een fout opgetreden bij het ophalen van het certificaat:', error);
        }
      };
      fetchCertificate();
    }
  }, [courseResultsId, link, store.lessonPlay, store.lessonPlay.courseResult.id, store.lmsApi]);

  const showLtiForm = isAccredited && isLti && hasRbNumberField;

  useEffect(() => {
    if (showLtiForm) {
      setOpenLtiForm(true);
    }
  }, [showLtiForm]);

  return (
    <>
      <LtiUserForm open={openLtiForm} handleClose={handleCloseLtiForm} />
      <Box className="scorePage" style={{ backgroundColor: '#EEF2FA', minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
        <Header />
        <Box style={{ marginTop: '151px', width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <Card className="scorePageCard" style={{ padding: '22px', borderRadius: '18px', boxShadow: 'none' }}>
            <Grid container>
              <Grid item>
                <Box marginBottom="27px">
                  <Typography variant="h5" fontSize="20px">
                    {t('scorePage.completed', { courseTitle })}
                  </Typography>
                </Box>

                <Box textAlign="center">
                  <Typography>{t('scorePage.downloadCertificate', { u, uw, kunt })}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography fontWeight="bold">{t('accreditated.question', { courseTitle })}</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
              {answersPdf && (
                <Button variant="contained" href={answersPdf} target="_blank">
                  {t('scorePage.downloadAnswers')} <SvgIcon component={download} color="white" style={{ marginLeft: '16px' }} />
                </Button>
              )}
              <Button disabled={disabled} variant="contained" href={link} target="_blank" onClick={loadCompleted}>
                {t('scorePage.approve')} <SvgIcon component={download} color="white" style={{ marginLeft: '16px' }} />
              </Button>
            </CardActions>
            {!isLti && (
              <Box style={{ textAlign: 'center' }}>
                <Button
                  id="course-overview-back-button"
                  aria-label=""
                  onClick={loadCompleted}
                  startIcon={<ArrowBackIcon />}
                  sx={{ textTransform: 'inherit' }}
                >
                  {t('scorePage.GoBack')}
                </Button>
              </Box>
            )}
          </Card>
        </Box>
      </Box>
      <CoursePointsAlert open={showPopup} msg="msg-5" handleClose={() => setShowPopup(false)} showConfirm btnBackCaption={t('ok')} />
    </>
  );
};

export default observer(LastStep);
