import useStore from 'hooks/useStore';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import { InputLabel, Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';

const AdvancedSelectLabel = ({ menuList, setLabelFilter }) => {
  const store = useStore();
  const { t } = useTranslation('adminCourses');
  const [label, setLabel] = useState(store.label);
  const uniqueLabels = [...new Set(menuList.map((item) => item.label))].filter((item) => item !== store.label);
  const labelFullName = { po: 'PO-Online', pe: 'PE-Academy', ewise: 'E-WISE', cme: 'CME-Online' };

  const handleChange = (event) => {
    setLabel(event.target.value);
    setLabelFilter(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }} size="small">
        <InputLabel id="selectLabel">Label</InputLabel>
        <Select labelId="selectLabel" id="demo-select-small" value={label} label="Label" onChange={handleChange}>
          <ListSubheader>{t('copyDialog.selPackage.currentLabel')}</ListSubheader>
          <MenuItem sx={{ marginLeft: '20px' }} value={store.label} key={store.label}>
            {labelFullName[store.label]}
          </MenuItem>
          <ListSubheader>{t('copyDialog.selPackage.otherLabels')}</ListSubheader>
          {uniqueLabels.map((item) => (
            <MenuItem sx={{ marginLeft: '20px' }} key={item} value={item}>
              {labelFullName[item]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

AdvancedSelectLabel.propTypes = {
  setLabelFilter: PropTypes.func,
  // copyValues: PropTypes.object,
  menuList: PropTypes.array
};

export default AdvancedSelectLabel;
