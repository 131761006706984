/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { types as t } from 'mobx-state-tree';
import {
  TextField,
  Links,
  RelProfession,
  RelCategories,
  RelSpecialAccess,
  IsoDate,
  DateRange,
  Category,
  Profession,
  SpecialAccess,
  File
} from './BaseModel';
import { RelAccreditationLinks, AccreditationLink } from './AccreditationLink';
import { RelLessonContainer, LessonContainer } from './LessonContainer';
import { LessonContentEdit } from './LessonContent';
import { Lesson } from './Lesson';
import { Author } from './Author';

const dispatchIncluded = (snapshot) => {
  if (snapshot.type === 'lesson_container') return LessonContainer;
  if (snapshot.type === 'lesson') return Lesson;
  if (snapshot.type === 'accreditation_link') return AccreditationLink;
  if (snapshot.type === 'author') return Author;
  if (snapshot.type === 'profession') return Profession;
  if (snapshot.type === 'category') return Category;
  if (snapshot.type === 'file--file') return File;
  // if (snapshot.type === 'lesson_content') return RelLessonContentSimple;
  if (snapshot.type === 'lesson_content') return LessonContentEdit;
  if (snapshot.type === 'special_access') return SpecialAccess;
  if (snapshot.type === 'course') return RelCourses;

  return null;
};

const Included = t.array(
  t.union(
    { dispatcher: dispatchIncluded },
    LessonContainer,
    Lesson,
    AccreditationLink,
    Author,
    Category,
    Profession,
    File,
    LessonContentEdit,
    SpecialAccess
    // RelCourses
  )
);

export const Course = t
  .model('Course', {
    id: t.identifier,
    type: t.string,
    included: t.optional(Included, []),
    attributes: t.model({
      drupal_internal__nid: t.number,
      langcode: t.string,
      status: t.boolean,
      title: t.string,
      created: IsoDate,
      changed: IsoDate,
      information: t.maybeNull(TextField),
      goals: t.maybeNull(TextField),
      preview_video: t.maybeNull(TextField),
      course_status: t.maybeNull(t.string),
      expected: t.boolean,
      online: t.boolean,
      // show_in_public: t.undefined,
      field_points: t.maybeNull(t.string),
      field_extra_points: t.maybeNull(t.string),
      field_extra_points_desc: t.maybeNull(t.string),
      field_accreditation_code: t.maybeNull(t.string),
      field_valid_period: t.maybeNull(DateRange),
      field_skip_evaluation: t.optional(t.boolean, false, [undefined, null])
    }),
    relationships: t.model({
      // owner: types.maybeNull(types.late(() => types.reference(User)))
      // eslint-disable-next-line arrow-body-style
      // accreditation_links: t.maybeNull(t.late(() => t.reference(RelAccreditationLinks))),
      accreditation_links: t.maybeNull(RelAccreditationLinks),
      course_category: RelCategories,
      course_category2: RelCategories,
      profession: RelProfession,
      lesson_container: RelLessonContainer,
      special_access: RelSpecialAccess
    }),
    links: Links
  })
  .views((self) => ({
    get isFavorite() {
      const { favoriteCourses } = window.store.user;
      const isFavorite = favoriteCourses.findIndex((value) => value.id === self.id);

      return isFavorite !== -1;
    },

    get profession() {
      return self.relationships.profession.data.id.attributes.name;
    },
    get specialAccess() {
      const { special_access } = self.relationships;
      return (
        special_access.data.map((data) => {
          const {
            id,
            attributes: { name }
          } = data.id;
          return {
            id,
            name,
            type: data.type
          };
        }) || []
      );
    },
    get categories() {
      const { course_category } = self.relationships;
      return (
        course_category.data.map((data) => {
          const {
            id,
            attributes: { name }
          } = data.id;
          return {
            id,
            title: name,
            type: data.type
          };
        }) || []
      );
    },
    get categories2() {
      const { course_category2 } = self.relationships;
      return (
        course_category2.data.map((data) => {
          const {
            id,
            attributes: { name }
          } = data.id;
          return {
            id,
            title: name,
            type: data.type
          };
        }) || []
      );
    }
  }))
  .actions((self) => ({
    setTitle(title) {
      self.attributes.title = title;
    }
  }));

export const newCourse = {
  type: 'course',
  attributes: {
    title: 'Titel van de nieuwe cursus',
    course_status: 'New',
    expected: false,
    online: false,
    show_in_public: false,
    goals: { value: `Type hier je goals`, processed: `` },
    information: { value: `Type hier de cursus information`, processed: `` },
    field_points: '',
    field_extra_points: '',
    field_accreditation_code: ''
  },
  relationships: {
    profession: { data: {} },
    // authors: { data: [] },
    course_category: { data: [] },
    course_category2: { data: [] },
    special_acesss: { data: [] },
    accreditation_links: { data: [] },
    lesson_container: { data: {} }
  }
};

export const courseStatus = [
  { id: 1, name: 'New', value: 'New' },
  { id: 2, name: 'Update', value: 'Update' },
  { id: 3, name: 'Update(manual)', value: 'Update(manual)' }
];

export const RelCourse = t.model('RelCourse', {
  data: t.maybeNull(
    t.model({
      id: t.reference(Course),
      type: t.string
    })
  ),
  links: Links
});

export const RelCourses = t
  .model('RelCourses', {
    data: t.maybeNull(
      t.array(
        t.model({
          id: t.maybeNull(t.reference(Course)),
          type: t.string
        })
      )
    ),
    links: Links
  })
  .views((self) => ({
    get courserefs() {
      if (self.data) {
        return self.data.map((course) => course.id);
      }

      return [];
    }
  }));
