import { ThemeBrandTitle } from 'helpers/Helpers';
import { getLessonPlay } from 'state/models/LessonPlay';
import { getEwapi, getSpecialAccessCourses, reloadAdminLesson } from 'state/RoutesActions';

const BrandTitle = ThemeBrandTitle();

export const lessonPlayRoutes = {
  name: 'lessonPlay',
  pattern: '/:productName/:product/:packageId/course/:courseId/lesson/:lessonId/page/:pageNo',
  meta: { title: `%%introduction.metatitle%% | ${BrandTitle}` },
  //
  beforeEnter: async (fromState, toState, routerStore) => {
    const { productName } = toState.params;
    const { store } = routerStore.options;

    //
    if (productName.includes('special-access')) {
      return getSpecialAccessCourses(fromState, toState, routerStore);
    }

    if (productName === 'redactie') return reloadAdminLesson(fromState, toState, routerStore);
    //
    await getEwapi(fromState, toState, routerStore);
    if (!store?.login?.hasSpecialAccess && store?.courses?.isCourseExpired(Number(toState.params.courseId))) {
      return store.routerStore.goTo('home');
    }
    return null;
  },
  //
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    //
    if (store.lessonState === 'pending') return; // Fix for being called twice
    //
    const pageNo = toState.params.pageNo !== undefined ? parseInt(toState.params.pageNo) : 1;
    //
    if (pageNo === 0 && !store.canEditCourse && !store.login.hasSpecialAccess) {
      routerStore.goTo('lessonPlay', {
        params: {
          product: toState.params.product,
          productName: toState.params.productName,
          packageId: toState.params.packageId,
          courseId: toState.params.courseId,
          lessonId: toState.params.lessonId,
          pageNo: '1'
        }
      });
      return;
    }
    try {
      //
      await getLessonPlay(store, toState.params.courseId, toState.params.lessonId);
      //
      const newPageNo = pageNo === 0 ? 0 : store.lessonPlay.getValidPage(pageNo);
      //
      const isLti = store.login.X4gJ2Kq === 1 ?? false;
      if (isLti) {
        store.setLtiSession();
      }
      //
      if (newPageNo !== pageNo) {
        routerStore.goTo('lessonPlay', {
          params: {
            product: toState.params.product,
            productName: toState.params.productName,
            packageId: toState.params.packageId,
            courseId: toState.params.courseId,
            lessonId: toState.params.lessonId,
            pageNo: newPageNo.toString()
          }
        });
      } else {
        store.lessonPlay.setMoveNewPage(pageNo);
      }
    } catch (e) {
      store.gotoErrorPage({ title: 'Error loading content', message: 'Click <a href="/">here</a> to go Back to the home page' });
    }
  }
};

export const defLessonPlayRoutes = {
  name: 'defLessonPlay',
  pattern: '/:productName/:product/:packageId/course/:courseId/lesson/:lessonId/page/:pageNo',
  meta: { title: `%%introduction.metatitle%% | ${BrandTitle}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    const lastLesson = window.store?.lesson_results?.findLessonByCourseNid(Number(window.store?.params?.courseId));
    const lastLessonPage = lastLesson ? lastLesson[0]?.page_id_max.toString() : '1';
    const goToPage = lastLesson[0]?.status === '2' || lastLesson[0]?.status === '3' ? '1' : lastLessonPage;
    routerStore.goTo('lessonPlay', {
      params: {
        product: toState.params.product,
        productName: toState.params.productName,
        packageId: toState.params.packageId,
        courseId: toState.params.courseId,
        lessonId: toState.params.lessonId,
        pageNo: goToPage ?? '1'
      }
    });
  }
};
