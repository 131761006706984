import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import { uniqueId } from 'lodash';
/* MUI */
import { Box, Typography, CircularProgress, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';

import { LessonArrayProgress } from 'helpers/Helpers';

const CourseLessonSection = (props) => {
  const { lessons } = props;
  const store = useStore();
  const { t } = useTranslation();
  const { isLessonFailed, findLessonByCourseNid } = store.lesson_results;

  const lessonResult = lessons && lessons.length > 1 && findLessonByCourseNid(Number(store.params.courseId));
  const lessonProgress = lessonResult && LessonArrayProgress(lessonResult, lessons);

  const toLesson = (lesson) => {
    store.routerStore.goTo('defLessonPlay', {
      params: {
        product: store.params.product,
        productName: store.params.productName,
        packageId: store.params.packageId,
        courseId: store.params.courseId,
        lessonId: lesson.nid.toString()
      }
    });
  };
  return (
    <Box className="course_lessons">
      <Typography variant="h2" component="h2" fontSize="20px" color="primaryBlue.main" fontWeight="500">
        {t('introduction.sidebar.lessons')}
      </Typography>
      {lessons &&
        lessons.length > 1 &&
        lessons.map((lesson, index) => (
          <Box key={uniqueId('lesson-id')} className="lessons_display">
            <Box display="flex" justifyContent="center" alignItems="center">
              {index + 1}
              <CircularProgress
                variant="determinate"
                value={lessonProgress[index] || 0}
                size={26}
                color="primary"
                sx={{
                  position: 'absolute',
                  left: -2,
                  zIndex: 157
                }}
              />
              <CircularProgress
                variant="determinate"
                value={100}
                size={26}
                sx={{
                  position: 'absolute',
                  left: -2,
                  zIndex: 1,
                  color: '#A3AED0'
                }}
              />
            </Box>
            <Box paddingLeft="10px" color="#A3AED0">
              {lesson.lesson_type}
            </Box>
            <Box display="flex" alignItems="center">
              {lessonProgress[index] === 100 && <CheckIcon sx={{ width: '17px', height: '16px' }} color="primary" />}
            </Box>
            <Box className="lesson_button">
              {(index === 0 || (lessonProgress[index - 1] === 100 && !isLessonFailed(lesson.nid)) || store.isEwiseUser) && (
                <IconButton onClick={() => toLesson(lesson)}>
                  <ArrowForwardIosIcon color="#A3AED0" sx={{ opacity: '0.5' }} />
                </IconButton>
              )}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

CourseLessonSection.propTypes = {
  lessons: PropTypes.any
};

export default observer(CourseLessonSection);
