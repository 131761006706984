import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialTable from '@material-table/core';
import { Grid, Box, Paper, Autocomplete } from '@mui/material';
import dayjs from 'dayjs';
import { downloadExcel } from 'react-export-table-to-excel';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

async function getData(query, courseId) {
  const searchParams = new URLSearchParams();
  const filters = query.filters.filter((filter) => ['is_trial', 'can_finish']
    .filter(Boolean).includes(filter.column.field))
    .reduce((acc, item) => ({ ...acc, [item.column.field]: item.value }), {});
  const sorting = query.orderByCollection.find((item) => item.sortOrder === 1);

  searchParams.set('amount', query.pageSize);
  searchParams.set('page', (query.page + 1));

  searchParams.set('sort_by', sorting?.orderByField);
  searchParams.set('sort_direction', sorting?.orderDirection);

  Object.entries(filters).forEach(([key, value]) => searchParams.set(key, value));

  const { data } = await window.authedClient.get(`/ewapi/course-result/course/${courseId}/active/json?${searchParams.toString()}`);

  return {
    data: data?.data || [],
    page: query.page,
    totalCount: data.meta.totalRecords
  };
}

const LessonTabStudents = () => {
  const [usersCount, setUsersCount] = useState(0);
  const { t } = useTranslation('editCourseForm');
  const store = useContext(Store);
  const courseId = store.courseEdit.currentCourse.attributes.drupal_internal__nid;

  const [columnFilters, setColumnFilters] = useState({});

  const exportFileName = `course-active-students-${courseId}-${dayjs().format('YYYYMMDDhhmmss')}`;

  const filterSelectOptions = {
    'false': t('tabStudents.no'),
    'true': t('tabStudents.yes')
  };

  const readableBoolean = (boolean = false) => boolean ? t('tabStudents.yes') : t('tabStudents.no')
  const readableDate = (timestamp = '') => timestamp ? dayjs(timestamp * 1000).format('DD-MM-YYYY') : ''

  const tableSingleFilter = (props) => (
    <SingleValueFilter
      {...props}
    />
  );

  const tableOptions = {
    maxColumnSort: 1,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 100],
    emptyRowsWhenPaging: false,
    search: false,
    showTitle: false,
    header: true,
    dense: true,
    thirdSortClick: false,
    rowStyle: { fontSize: '14px', '&:hover': { background: 'silver' } },
    defaultOrderByCollection: [{
      orderBy: 4,
      orderByField: 'start_date',
      orderDirection: 'desc',
      sortOrder: 1
    }],
    draggable: false,
    filtering: true,
    exportMenu: [
      {
        label: t('tabStudents.export.asExcel'),
        exportFunc: (cols, data) => {
          downloadExcel({
            fileName: exportFileName,
            sheet: exportFileName,
            tablePayload: {
              header: cols.map((col) => col.title),
              body: data
            }
          });
        }
      }
    ],
  };

  const tableColumns = [
    {
      title: t('tabStudents.firstName'),
      field: 'user_first_name',
      sorting: false,
      filtering: false
    },
    {
      title: t('tabStudents.lastName'),
      field: 'user_last_name',
      sorting: false,
      filtering: false
    },
    {
      title: t('tabStudents.email'),
      field: 'user_email',
      sorting: false,
      filtering: false
    },
    {
      title: t('tabStudents.productTitle'),
      field: 'subscription_product_title',
      sorting: false,
      filtering: false
    },
    {
      title: t('tabStudents.courseStarted'),
      field: 'start_date',
      render: (rowData) => (readableDate(rowData?.start_date)),
      customExport: (rowData) => (readableDate(rowData?.start_date)),
      sorting: true,
      filtering: false,
    },
    {
      title: t('tabStudents.canFinishCourse'),
      field: 'can_finish',
      render: (rowData) => (readableBoolean(rowData?.can_finish)),
      customExport: (rowData) => (readableBoolean(rowData?.can_finish)),
      sorting: false,
      lookup: filterSelectOptions,
      filterComponent: tableSingleFilter,
      defaultFilter: columnFilters?.can_finish
    },
    {
      title: t('tabStudents.trialStudent'),
      field: 'is_trial',
      render: (rowData) => (readableBoolean(rowData?.is_trial)),
      customExport: (rowData) => (readableBoolean(rowData?.is_trial)),
      sorting: false,
      lookup: filterSelectOptions,
      filterComponent: tableSingleFilter,
      defaultFilter: columnFilters?.is_trial
    }
  ];

  return (
    <Box className="box-left" sx={{ flexGrow: 1 }}>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <Box component="h3">Studenten bezig met deze cursus: {usersCount}</Box>
        </Grid>
        <Grid item sx={sxTableCourseUsersInprogress}>
          <MaterialTable
            data={(query) =>
              new Promise((resolve, reject) => {
                getData(query, courseId)
                  .then((result) => {
                    setUsersCount(result.totalCount);
                    resolve(result);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            options={tableOptions}
            columns={tableColumns}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />
            }}
            localization={{ body: { emptyDataSourceMessage: 'No students found' } }}
            onFilterChange={(newFilters) => {
              setColumnFilters(
                Object.fromEntries(
                  newFilters.map((filter) => [
                    filter.column.field,
                    filter?.value || ''
                  ])
                )
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(LessonTabStudents);

const sxTableCourseUsersInprogress = {
  margin: '0px',
  'tbody td': { padding: '8px' },
  'tbody tr.MuiTableRow-root:hover': { background: '#c0c0c04d' }
};

const SingleValueFilter = ({columnDef, onFilterChanged}) => {
  const handleChange = (event, value) => {
    onFilterChanged(columnDef.tableData.id, value);
  }

  return (
    <Autocomplete
      key={columnDef.field}
      options={Object.keys(columnDef.lookup)}
      getOptionLabel={(option) => `${columnDef.lookup[option] || ''}`}
      defaultValue={columnDef.defaultFilter}
      onChange={handleChange}
      isOptionEqualToValue={(compareOption, compareValue) => compareOption === compareValue}
      renderInput={(params) => (
        <TextField {...params} placeholder={columnDef.title} value=" " />
      )}
      style={{ width: 120 }}
    />
  );
};

SingleValueFilter.propTypes = {
  columnDef: PropTypes.object.isRequired,
  onFilterChanged: PropTypes.func.isRequired
};
