/* eslint-disable camelcase */
import i18n from 'i18next';
import { toJS } from 'mobx';
import { getParent, types as t } from 'mobx-state-tree';
import { Author } from './Author';
import { Category } from './Profession';

const drupalBaseUrl = process.env.REACT_APP_JSONAPI;

export const Lesson = t.model('Lesson', {
  nid: t.identifierNumber,
  uuid: t.string,
  max_attempts: t.maybeNull(t.number),
  minimum_score: t.maybeNull(t.number),
  minimum_score_2nd_try: t.maybeNull(t.number),
  delta: t.maybeNull(t.number),
  free_flow: t.maybeNull(t.string),
  lesson_type: t.maybeNull(t.string)
});

export const Course = t
  .model('Course', {
    course_accreditation: t.maybeNull(t.string),
    course_delta: t.number,
    course_expected: t.number,
    course_extra_points: t.maybeNull(t.number),
    course_goals: t.maybeNull(t.string),
    course_id: t.maybeNull(t.identifierNumber),
    course_information: t.maybeNull(t.string),
    course_points: t.number,
    course_preview_video: t.maybeNull(t.string),
    course_profession: t.number,
    course_skip_evaluation: t.optional(t.number, 0),
    course_status: t.maybeNull(t.string),
    course_title: t.string,
    course_uuid: t.string,
    coursephoto_alt: t.maybeNull(t.string),
    coursephoto_url: t.maybeNull(t.string),
    headerphoto_alt: t.maybeNull(t.string),
    headerphoto_url: t.maybeNull(t.string),
    lc_course_header_photo: t.maybeNull(t.number),
    lc_coursephoto: t.maybeNull(t.number),
    lc_extra_label: t.maybeNull(t.string),
    lc_goals: t.maybeNull(t.string),
    lc_information: t.maybeNull(t.string),
    lc_podcast_html: t.maybeNull(t.string),
    lc_podcast_title: t.maybeNull(t.string),
    lc_preview_video: t.maybeNull(t.string),
    lesson_container_id: t.number,
    lesson_container_uuid: t.string,
    valid_period_end: t.maybeNull(t.string),
    valid_period: t.maybeNull(t.string),
    lc_author_id: t.maybeNull(t.array(t.safeReference(Author))), // array ref per autore
    lc_reviewer_id: t.maybeNull(t.array(t.safeReference(Author))), // array ref per autore
    // course_category: t.maybeNull(t.array(t.safeReference(Category))), // array ref per cat
    course_extra_points_desc: t.maybeNull(t.string),
    course_category: t.maybeNull(t.array(t.safeReference(t.late(() => Category)))),
    course_category2: t.maybeNull(t.array(t.safeReference(Category))), // array ref per cat
    lessons: t.maybeNull(t.array(Lesson)), // array ref per less
    tab_order: t.maybeNull(t.number), // For search
    package_title: t.maybeNull(t.string), // For search
    package_id: t.maybeNull(t.number), // For search
    package_accr: t.maybeNull(t.number), // For search
    isTrialCourse: t.maybeNull(t.boolean)
  })
  .views((self) => ({
    get categoryLabel() {
      return self.course_category?.map((category) => category.name).join(' ');
    },
    get categoryLabelArray() {
      return self.course_category?.map((category) => category.name);
    },
    get points() {
      // Special access points
      if (getParent(self, 3).routerState.routeName.includes('specialAccess')) {
        return `${self.course_points} ${i18n.t('course.accr.duration')}`;
      }
      // Accreditation points
      const isSearchRoute = window.store.routerStore?.getCurrentRoute().name === 'searchOverview';
      const selectedPackageId = isSearchRoute ? self.package_id : getParent(self, 3)?.selectedPackageId;
      const accreditation = getParent(self, 3)?.baseProduct?.packages.find((packag) => packag.nid === selectedPackageId);
      if (accreditation?.accreditation) {
        const { accr_unit, accr_unit_plural, accr_unit_short, extra_points_unit } = accreditation.accreditation;
        const accrUnit = self.course_points <= 1 ? accr_unit : accr_unit_plural;
        if (self.course_extra_points_desc) {
          return `${self.course_extra_points_desc}`;
        }
        if (self.course_extra_points) {
          return `${self.course_points} ${accr_unit_short || accrUnit} + ${self.course_extra_points} ${extra_points_unit}`;
        }
        return `${self.course_points} ${accr_unit_short || accrUnit || 'uren'}`;
      }
      return `${self.course_points} ${i18n.t('course.accr.duration')}`;
    },
    get imageUrl() {
      return self.coursephoto_url && `${drupalBaseUrl}${self.coursephoto_url}`;
    }
  }));

export const Courses = t
  .model('Courses', {
    courses: t.array(Course),
    filter: t.optional(
      t.model({
        authorId: t.maybeNull(t.number),
        categories: t.maybeNull(t.array(t.number)),
        competencies: t.maybeNull(t.array(t.number)),
        hasPodcast: t.maybeNull(t.boolean),
        statuses: t.maybeNull(t.array(t.string)),
        isExpiring: t.maybeNull(t.boolean)
      }),
      {
        authorId: null,
        categories: [],
        competencies: [],
        statuses: [],
        hasPodcast: false,
        isExpiring: false
      }
    )
  })
  .volatile(() => ({
    sortedPackage: []
  }))
  .views((self) => ({
    get onlyTeachers() {
      return getParent(self).authors.authorList.filter((author) => !self.nonAuthorsInAdvisoryBoard.includes(author.id));
    },
    get advisoryBoardMembers() {
      const advisoryBoardIds = getParent(self).profession.advisory_board;
      return advisoryBoardIds.map((advId) => getParent(self).authors.authorList.find((author) => author.id === advId));
    },
    get nonAuthorsInAdvisoryBoard() {
      const parsedCourses = JSON.parse(JSON.stringify(self.courses));
      const advisoryBoardIds = getParent(self).profession.advisory_board;
      const isNoAuthor = advisoryBoardIds
        .map((advId) => ({
          advId,
          isAuthor: parsedCourses.some((course) => course.lc_author_id.includes(advId))
        }))
        .filter((item) => !item.isAuthor)
        .map((item) => item.advId);
      return isNoAuthor;
    },
    /** used by course filter  */
    get courseList() {
      if (!self.filter?.competencies?.length && !self.filter?.statuses?.length && !self.filter?.hasPodcast && !self.filter?.isExpiring) {
        return self.courseListfilteredByCategories;
      }
      //
      const coursesIdsSet = new Set(); // used to avoid duplicate courses
      //
      const coursesFilteredByCompetence = self.filter?.competencies?.length
        ? self.courseListfilteredByCategories.filter((course) => {
            if (coursesIdsSet.has(course.course_id)) {
              return false;
            }
            const ids = course.course_category2.map((c) => c.tid);
            const found = ids.find((id) => self.filter?.competencies.includes(id));
            if (found) {
              coursesIdsSet.add(course.course_id);
            }
            return !!found;
          })
        : [];
      //
      const coursesFilteredByStatuses = self.filter?.statuses?.length
        ? self.courseListfilteredByCategories.filter((course) => {
            if (coursesIdsSet.has(course.course_id)) {
              return false;
            }
            const exist = self.filter?.statuses.includes(course.course_status);
            if (exist) {
              coursesIdsSet.add(course.course_id);
            }
            return exist;
          })
        : [];
      //
      const coursesFilteredByHasPodcasts = self.filter?.hasPodcast
        ? self.courseListfilteredByCategories.filter((course) => {
            if (coursesIdsSet.has(course.course_id)) {
              return false;
            }
            const isPodcast = Boolean(course.lc_podcast_html);
            if (isPodcast) {
              coursesIdsSet.add(course.course_id);
            }
            return isPodcast;
          })
        : [];

      const coursesFilteredByIsExpiring = self.filter?.isExpiring
        ? self.courseListfilteredByCategories.filter((course) => {
            if (coursesIdsSet.has(course.course_id)) {
              return false;
            }
            const expirationDate = new Date(course.valid_period_end).setHours(23, 0, 0, 0);
            const isCourseExpiring = new Date().getTime() >= new Date(expirationDate - 60 * 24 * 60 * 60 * 1000).getTime();
            if (isCourseExpiring) {
              coursesIdsSet.add(course.course_id);
            }
            return isCourseExpiring;
          })
        : [];
      //
      //
      return [
        ...coursesFilteredByCompetence,
        ...coursesFilteredByStatuses,
        ...coursesFilteredByHasPodcasts,
        ...coursesFilteredByIsExpiring
      ].sort((a, b) => (a.course_title > b.course_title ? 1 : -1));
    },
    /** used by course filter  */
    get courseListfilteredByCategories() {
      let currentList = self.filter?.authorId ? self.getAuthorCourseList(self.filter?.authorId) : self.courses;
      //
      if (self.filter?.categories?.length) {
        const coursesIdsSet = new Set(); // used to avoid duplicate courses
        currentList = currentList.filter((course) => {
          const courseCategories = course.course_category.map((c) => c.tid);
          const found = courseCategories.find((c) => self.filter?.categories.includes(c));
          if (found && !coursesIdsSet.has(course.course_id)) {
            coursesIdsSet.add(course.course_id);
            return true;
          }
          return false;
        });
      }
      //
      /* clean current filter, (previous filter 'compentece', 'podcast' 'statuses' may not exist in current list) */
      self.cleanFilters(currentList);

      return currentList;
    },
    get categories() {
      let currentList = self.courses;
      if (self.filter?.competencies?.length || self.filter?.hasPodcast || self.filter?.statuses?.length || self.filter?.isExpiring) {
        currentList = self.courseList;
      }
      if (self.filter?.authorId) {
        currentList = self.getAuthorCourseList(self.filter?.authorId);
      }
      // TODO: DELETE NEXT LINE BEFORE PRODUCTION
      const mapCategories = {};
      currentList.forEach((course) => {
        course.course_category.forEach((c) => {
          if (!mapCategories[c.tid]) {
            mapCategories[c.tid] = { ...c, count: 1 };
          } else {
            mapCategories[c.tid].count += 1;
          }
        });
      });

      return Object.values(mapCategories).sort((a, b) => (a.weight > b.weight ? 1 : -1));
    },
    get competencies() {
      const mapCompetencies = {};
      self.courseListfilteredByCategories.forEach((course) => {
        course.course_category2.forEach((c) => {
          if (!mapCompetencies[c.tid]) {
            mapCompetencies[c.tid] = { ...c, count: 1 };
          } else {
            mapCompetencies[c.tid].count += 1;
          }
        });
      });
      return Object.values(mapCompetencies).sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    get statuses() {
      const mapStatuses = {};
      self.courseListfilteredByCategories.forEach((course) => {
        if (course.course_status && course.course_status !== 'Default') {
          if (!mapStatuses[course.course_status]) {
            mapStatuses[course.course_status] = { name: course.course_status, count: 1 };
          } else {
            mapStatuses[course.course_status].count += 1;
          }
        }
      });
      return Object.values(mapStatuses).sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    get countPodcastes() {
      let count = 0;
      self.courseListfilteredByCategories.forEach((course) => {
        if (course.lc_podcast_html) {
          count += 1;
        }
      });
      return count;
    },
    get countExpiringCourses() {
      let count = 0;
      self.courseListfilteredByCategories.forEach((course) => {
        const expirationDate = new Date(course.valid_period_end).setHours(23, 0, 0, 0);
        const isCourseExpiring = new Date().getTime() >= new Date(expirationDate - 60 * 24 * 60 * 60 * 1000).getTime();
        if (isCourseExpiring) {
          count += 1;
        }
      });
      return count;
    },
    get isFiltering() {
      if (self.isSpecialAccessView) return true;
      return Boolean(
        self.filter?.authorId ||
          self.filter?.categories?.length ||
          self.filter?.competencies?.length ||
          self.filter?.hasPodcast ||
          self.filter?.statuses?.length ||
          self.filter?.isExpiring
      );
    },
    get coursesCount() {
      return self.courses.length;
    },
    coursesByCategoryId(id) {
      return self.courses.filter((course) => {
        const categoryIds = course.course_category?.map((category) => category.tid);
        return categoryIds.includes(id);
      });
    },
    get isSpecialAccessView() {
      return getParent(self).routerState.routeName.includes('specialAccess');
    },
    isCourseExpired(id) {
      const getCourse = getParent(self, 1)?.findCourseByNid(id);
      const endDate = getCourse?.valid_period_end;
      return new Date() >= new Date(endDate).setHours(23, 0, 0, 0);
    },
    hideExpiredLabel(id) {
      const hideExpiredOnLabels = ['pe', 'po'];
      const label = getParent(self, 1)?.label;
      const hideExpLabel = hideExpiredOnLabels.includes(label);
      const getCourse = getParent(self, 1)?.findCourseByNid(id);
      const endDate = getCourse?.valid_period_end;
      const endDateArray = endDate?.split('-') || [];
      const isLastDayYear = endDateArray[1] === '12' && endDateArray[2] === '31';
      return hideExpLabel && isLastDayYear;
    },
    isCourseExpiring(id) {
      const getCourse = getParent(self, 1)?.findCourseByNid(id);
      const endDate = getCourse?.valid_period_end;
      const expirationDate = new Date(endDate).setHours(23, 0, 0, 0);
      return new Date().getTime() >= new Date(expirationDate - 60 * 24 * 60 * 60 * 1000).getTime();
    },
    isAccreditated(id) {
      const getCourse = getParent(self, 1)?.findCourseByNid(id);
      const accr = getCourse?.package_accr || 0;
      return accr;
    },
    getCoursePackage(id) {
      const getCourse = getParent(self, 1)?.findCourseByNid(id);
      return getCourse?.package_title || '';
    },
    //
    //
    //
    // TODO remove coursesByCategory (used in courseIntroduction and RelatedCourses), use courses or courseList and categories instead
    coursesByCategory(type) {
      const courses = type === 'course' ? self.courses : self.sortedPackage;
      const categories = [];
      courses.forEach((course) => {
        course.course_category.forEach((c) => {
          const existingCategory = categories.find((category) => category.name === c.name);
          if (existingCategory) {
            existingCategory.courses.push(course);
          } else {
            categories.push({
              name: c.name,
              courses: [course],
              weight: c.weight
            });
          }
        });
      });
      categories.sort((a, b) => a.weight - b.weight);
      const sortedCategories = [];
      categories.forEach((category) => {
        sortedCategories[category.name] = category.courses;
      });
      return sortedCategories;
    },
    getAuthorCourseList(authorId) {
      return self.courses.filter((course) => toJS(course.lc_author_id).find((author) => author.id === authorId));
    },
    // For search
    coursesByAccreditation() {
      const accrCategories = {
        accr: [],
        nonaccr: []
      };

      self.courses.forEach((course) => {
        if (course.package_accr === 1) {
          accrCategories.accr.push(course);
        } else {
          accrCategories.nonaccr.push(course);
        }
      });

      return accrCategories;
    }
  }))
  .actions((self) => ({
    addNewCourse(course) {
      const existingCourse = self.courses.find((c) => c.course_uuid === course.course_uuid);
      if (!existingCourse) self.courses.push(course);
    },
    filterByAuthor(authorId) {
      self.resetFilter();
      self.filter.authorId = authorId;
    },
    filterByCategories(categoryId) {
      const exist = self.filter.categories.includes(categoryId);
      if (exist) {
        self.filter.categories = self.filter.categories.filter((id) => id !== categoryId);
      } else {
        self.filter.categories.push(categoryId);
      }
    },
    filterByStatuses(status) {
      const exist = self.filter.statuses.includes(status);
      if (exist) {
        self.filter.statuses = self.filter.statuses.filter((value) => value !== status);
      } else {
        self.filter.statuses.push(status);
      }
    },
    filterByCompetencies(competence) {
      const exist = self.filter.competencies.includes(competence);
      if (exist) {
        self.filter.competencies = self.filter.competencies.filter((value) => value !== competence);
      } else {
        self.filter.competencies.push(competence);
      }
    },
    filterByPodcasts() {
      self.filter.hasPodcast = !self.filter.hasPodcast;
    },
    filterByExpiringCourses() {
      self.filter.isExpiring = !self.filter.isExpiring;
    },
    resetFilter() {
      self.filter = {
        authorId: null,
        categories: [],
        competencies: [],
        statuses: [],
        hasPodcast: false,
        isExpiring: false
      };
    },
    cleanFilters(currentList) {
      // clean filter competence array
      self.filter.competencies = self.filter.competencies.reduce((acc, competenceId) => {
        const found = currentList.find((course) => {
          const ids = course.course_category2.map((c) => c.tid);
          return ids.includes(competenceId);
        });
        if (found) {
          acc.push(competenceId);
        }
        return acc;
      }, []);
      //
      // clean filter statuses array
      self.filter.statuses = self.filter?.statuses?.reduce((acc, status) => {
        const found = currentList.find((course) => course.course_status === status);
        if (found) {
          acc.push(status);
        }
        return acc;
      }, []);
      //
      // clean filter podcast value
      if (self.filter?.hasPodcast) {
        const found = currentList.find((course) => Boolean(course.lc_podcast_html));
        self.filter.hasPodcast = Boolean(found);
      }
      //

      if (self.filter?.isExpiring) {
        const found = currentList.find((course) => {
          const expirationDate = new Date(course.valid_period_end).setHours(23, 0, 0, 0);
          return new Date().getTime() >= new Date(expirationDate - 60 * 24 * 60 * 60 * 1000).getTime();
        });
        self.filter.isExpiring = Boolean(found);
      }
    }
  }));
