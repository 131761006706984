/* Core */
import { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
/* MUI */
import { Box } from '@mui/material';
import Card from '@mui/material/Card';

/* Helpers */
import { urlify } from 'state/Utils';
import { useIntersection } from 'hooks';

/* CARD Elements */
import CourseSymbols from './elements/CourseSymbols';
import PodcastIcon from './elements/PodcastIcon';
import CourseReactions from './elements/CourseReactions';
import HideCourse from './elements/HideCourse';
import CourseImage from './elements/CourseImage';
import CourseStatus from './elements/CourseStatus';
import CourseCategories from './elements/CourseCategories';
import CourseTitle from './elements/CourseTitle';
import CourseAuthors from './elements/CourseAuthors';
import CoursePoints from './elements/CoursePoints';

/*
In summary, the BasicCard component is responsible for rendering a course card with essential course information.
It handles navigation to the course introduction page and manages the visibility of the course image.
The appearance and behavior of the card are determined by the data from the props and the application's store, along with user interactions.
*/
const BasicCard = (props) => {
  const store = useContext(Store);
  const {
    chip,
    categoriesArray,
    duration,
    courseTitle,
    authors,
    badges,
    nid,
    id,
    courseImage,
    podcast,
    extraLabel,
    lessons,
    hideCourse,
    reviewers,
    isTrialCourse
  } = props;
  const [visible, setVisible] = useState(false);

  const nonTrialCourse = store?.product?.free_product && store?.trialCourses && !isTrialCourse ? 'nonTrialCourse' : '';

  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setVisible(true);
  });

  const gotoSpecialAccessCourse = (courseId, title) =>
    store.routerStore.goTo('specialAccessCourseIntroduction', {
      params: {
        sa: 'my',
        productName: 'special',
        product: 'access',
        packageId: '0',
        courseId: courseId.toString(),
        title: urlify(title)
      }
    });

  // eslint-disable-next-line consistent-return
  const gotoCourse = (courseId, title) => {
    if (store.courses.isSpecialAccessView) {
      return gotoSpecialAccessCourse(courseId, title);
    }
    // microlearnings
    if (store.isMicrolearning && lessons[0]?.nid) {
      return store.routerStore.goTo('defLessonPlay', {
        params: {
          product: store.params.product,
          productName: store.params.productName,
          packageId: store.params.packageId,
          courseId: courseId.toString(),
          lessonId: lessons[0].nid?.toString(),
          pageNo: store?.lesson_results?.getLessonPageIdMax(+lessons[0].nid).toString() || '1'
        }
      });
    }

    return store.routerStore.goTo('courseIntroduction', {
      params: {
        productName: store.baseProduct.unique_name,
        product: store.selectedProductId.toString(),
        packageId: store.selectedPackageId.toString(),
        courseId: courseId.toString(),
        title: urlify(title)
      }
    });
  };

  const isFinished = store.courseResults.isCourseFinished(nid);
  const isCourseStarted = !isFinished && store.courseResults.isCourseStarted(nid);
  const isCourseExpiring = store.courses.isCourseExpiring(nid);

  return (
    <Box className={`course_overview_card onboarding-course ${nonTrialCourse}`}>
      <Card data-testid="course-card" className={`card__wrapper ${hideCourse ? 'hidden' : chip} `}>
        <Box position="relative" ref={imgRef} className={`card__img ${isFinished || isCourseStarted}`}>
          <Box className="click__wrapper" onClick={() => gotoCourse(nid, courseTitle)}>
            <CourseImage visible={visible} src={courseImage} alt={courseTitle} goToCourse={gotoCourse} />
          </Box>
          <CourseCategories categories={categoriesArray} noStatus={chip || isCourseExpiring} />
          <CourseStatus status={chip} isCourseExpiring={isCourseExpiring} nid={nid} />
          <CourseReactions id={id} lessons={lessons} nid={nid} />
        </Box>
        <Box className="card__content" onClick={() => gotoCourse(nid, courseTitle)}>
          <Box className="card__title">
            <CourseTitle courseTitle={courseTitle} courseId={nid} extraLabel={extraLabel} />
          </Box>
          <Box className="card__authors">
            <CourseAuthors authors={[...(authors || []), ...(reviewers || [])]} />
          </Box>
          <Box className="card__symbols_points" onClick={() => gotoCourse(nid, courseTitle)}>
            <Box className="card__symbols">
              <CourseSymbols badges={badges} />
            </Box>
            <Box className="card__points">
              {podcast && <PodcastIcon />}
              {duration && <CoursePoints points={duration} />}
            </Box>
          </Box>
        </Box>
        {hideCourse && <HideCourse id={id} />}
      </Card>
    </Box>
  );
};

/* Our logo props */
BasicCard.propTypes = {
  chip: PropTypes.string,
  categoriesArray: PropTypes.array,
  duration: PropTypes.string,
  courseTitle: PropTypes.string,
  authors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  badges: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  nid: PropTypes.number,
  id: PropTypes.string,
  courseImage: PropTypes.string,
  podcast: PropTypes.any,
  extraLabel: PropTypes.any,
  lessons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hideCourse: PropTypes.bool,
  reviewers: PropTypes.array,
  isTrialCourse: PropTypes.bool
};

export default observer(BasicCard);
